import React, { useEffect, useState } from 'react';
import { Container, List, ListItem, ListItemText } from '@mui/material';
import Controls from '../../../../../components/Entry/Controls';

const { Input } = Controls;

function Collection({ data, onChange, label }) {
    const [collectionData, setCollectionData] = useState(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedObjectId, setSelectedObjectId] = useState(null);

    useEffect(() => {
        setCollectionData(data);
    }, [data]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        const filteredData = data.filter(object =>
            object.code.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setCollectionData(filteredData);
    };

    const handleItemClick = (object) => {
        setSelectedObjectId(object.code); 
        setSearchTerm(`${object.code}`);
        onChange(object.id); 
    };

    return (
        <>
            <Input label={label} value={searchTerm} onChange={handleInputChange} />

            <Container
                sx={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    overflowY: 'scroll',
                    maxHeight: '250px',
                    overflowX: 'hidden'
                }}
            >
                <List>
                    {collectionData.map((object, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleItemClick(object)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <ListItemText primary={`${object.code}`} />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </>
    );
}

export default Collection;
