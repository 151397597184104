import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'

import Controls from '../../components/Entry/Controls'
import PageHeader from '../../components/Typography/PageHeader'
import CustomerOrderList from './components/CustomerOrderList'
import CustomerOrderService from './services/CustomerOrder'
import { useAuth } from '../../applications/hooks/UseAuth'

const CURRENT_PAGE = 'agroforestry_plantation'

function CustomerOrder() {
    const service = CustomerOrderService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = false

    const navigate = useNavigate()

    const addAction = () => {
        navigate('/app/commercial/customer/new')
    }

    return (
        <>
            <PageHeader title={'Gestion Clients'} subTitle={'Gestion Clients'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <Controls.Button text="Nouveau" variant="contained" size="large" onClick={addAction} />
                </div>
            </PageHeader>

            <CustomerOrderList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={() => <Form />}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default CustomerOrder
