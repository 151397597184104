import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../applications/hooks/UseAuth'
import ContentView from '../../../components/PageView/Content'
import CustomerOrderService from '../services/CustomerOrder'
import { CustomerOrderForm } from './CustomerOrderForm'

function CustomerOrderView(props) {
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const customerOrderService = CustomerOrderService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    return (
        <>
            <ContentView
                dataId={dataId}
                title={dataId !== 'new' ? `Modification client` : `Ajouter client`}
                service={customerOrderService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={CustomerOrderForm}
                needLoadCode={needLoadCode}
            />
        </>
    )
}

export default CustomerOrderView
