import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../applications/hooks/UseAuth'
import ContentView from '../../../components/PageView/Content'
import OrderTrackingService from '../services/OrderTracking'
import { OrderTrackingForm } from './OrderTrackingForm'
import { OrderTrackingFormMangrove } from './OrderTrackingFormMangrove'

function OrderTrackingView(props) {
    const { addReturnButton, recordForEdit } = props
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const orderTrackingService = OrderTrackingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    const mangro = location.pathname.split('/')[2] === 'mangrove'

    return (
        <>
            <ContentView
                dataId={recordForEdit ? recordForEdit.id : dataId}
                title={recordForEdit ? '' : 'Nouveau commande'}
                service={orderTrackingService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={!mangro ? OrderTrackingForm : OrderTrackingFormMangrove}
                addReturnButton={addReturnButton}
                needLoadCode={needLoadCode}
            />
        </>
    )
}

export default OrderTrackingView
