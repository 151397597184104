export function getCodeDataSet(data){
    let optionValue = data.options[0].value
    let code = optionValue.split('/')[1]
    return code

}

export function getCodeCollection(data){
    let code = getCodeDataSet(data)
    return code
}

export function findObjectsByName(array, searchString) {
    return array.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
}

export function isObject(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
}