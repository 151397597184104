// import { Http } from '../../../applications/Http.js'

import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class OrderTrackingService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/order_tracking')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addOrderTracking(data) {
        try {
            const response = await Http.put('/api/order_tracking', data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout .")
        }
    }

    static async updateOrderTracking(id, data) {
        try {
            const response = await Http.patch(['/api/order_tracking', id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }

    static async getAllOrderTracking() {
        try {
            const response = await Http.get('/api/order_tracking')
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }
}

export default OrderTrackingService
