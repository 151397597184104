import React from 'react'
import Subtitle from '../../Typography/Subtitle'
import { makeStyles } from '@mui/styles'

const useStyle ={
    page:{
        padding: `30px`,
    },
    title: {
        padding: `0 0 30px 0 `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold',
    }
}

function FormContent(props) {
    const { title, data, Form, service, needLoad, setNeedLoad } = props
    const classes = useStyle
    return (
        <div style={classes.page} >
            <div style={classes.title}>{title}</div>
            {Form && <Form records={data} service={service} needLoad={needLoad} setNeedLoad={setNeedLoad}></Form>}
        </div>
    )
}

export default FormContent
