import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'

import PageHeader from '../../components/Typography/PageHeader'
import { useAuth } from '../../applications/hooks/UseAuth'
import OrderTrackingList from '../order_tracking/components/OrderTrackingList'
import OrderTrackingMangroService from './services/MangroCustomerOrder'
import OrderTrackingView from '../order_tracking/components/OrderTrackingView'

const CURRENT_PAGE = 'agroforestry_plantation'

function MangroCustomerOrder() {
    const service = OrderTrackingMangroService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true

    return (
        <>
            <PageHeader title={'Commande Mangroves'} subTitle={'Commande Mangroves'}></PageHeader>

            <OrderTrackingList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={OrderTrackingView}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default MangroCustomerOrder
