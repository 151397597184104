import React, { useState } from 'react';
import { Radio, FormControlLabel, List, ListItem } from '@mui/material';

function SingleChoiceInput({ options, onChange }) {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (optionLabel) => {
        if (selectedOption === optionLabel) {
            setSelectedOption(null); // Désélectionner l'option si elle est déjà sélectionnée
        } else {
            setSelectedOption(optionLabel); // Sélectionner l'option cliquée
            onChange(optionLabel); // Appeler la fonction onChange avec l'option sélectionnée
        }
    };

    return (
        <div>
            <List>
                {options.map((option, index) => (
                    <ListItem key={index} onClick={() => handleOptionClick(option.label)} style={{ cursor: 'pointer' }}>
                        <FormControlLabel
                            control={<Radio checked={selectedOption === option.label} />}
                            label={option.label}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default SingleChoiceInput;
