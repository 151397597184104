import React, { useEffect, useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import DataSetsService from '../../../../datasets/services/DataSets'
import Loader from '../../../../../components/Entry/Loading'
import { getCodeDataSet } from '../../../utils/function/DataSet'

const { SelectDataSet } = Controls

function DataSetInput(props) {
    const { data, onChange } = props
    const defaultData = {
        id: 0,
        name: '',
        title: ''
    }
    const [dataset, setDataset] = useState([defaultData])
    const [loading, setLoading] = useState(false)
    const [selectedData, setSelectedData] = useState('')

    const dataSetService = DataSetsService.load()

    useEffect(() => {
        if (data) {
            setLoading(true)
            let code = getCodeDataSet(data)
            dataSetService
                .getDataCode(code)
                .then((data) => {
                    let response = data.data[0].database
                    let formatedData = JSON.parse(response)
                    setDataset(formatedData)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [data, dataSetService])


    const handleChangeData = (e) => {
        setSelectedData(e.target.value)
        onChange(e.target.value)
    }
    return (
        <>
            <Loader isLoading={loading} />
            <SelectDataSet
                label={data.options[0].label}   
                options={dataset || [defaultData]}
                value={selectedData || ''}
                onChange={handleChangeData}
                labelKey="label"
                valueKey="label"
            ></SelectDataSet>
        </>
    )
}

export default DataSetInput
