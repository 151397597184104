import ActionButton from './ActionButton'
import AutoCompleteSelect from './AutoCompleteSelect'
import AvatarContainer from './AvatarContainer'
import Button from './Button'
import Checkbox from './Checkbox'
import ChipButton from './ChipButton'
import CustomCircularPercentage from './CustomCircularPercentage'
import CustomFileView from './CustomFileView'
import CustomImageAvatar from './CustomImageAvatar'
import CustomizedButton from './CustomizedButton'
import CustomRadioGroup from './CustomRadioGroup'
import CustomSelect from './CustomSelect'
import CustomTimePicker from './CustomTimePicker'
import DatePicker from './DatePicker'
import DynamicTextInput from './DynamicTextInput'
import ExportButton from './ExportButton'
import FileInput from './FileInput'
import GpsCoordinatesInput from './GpsCoordinatesInput'
import IconButton from './IconButton'
import ImageAvatar from './ImageAvatar'
import ImageInput from './ImageInput'
import Input from './Input'
import InputNumber from './InputNumber'
import InputPassword from './InputPassword'
import Loader from './Loading'
import ModifyButton from './ModifyButton'
import MultilineInput from './MultilineInput'
import MultipleFileInput from './MultipleFileInput'
import MultipleImageInput from './MultipleImageInput'
import MultipleSelect from './MultipleSelect'
import ProgressBar from './ProgressBar'
import QrViewer from './QrViewer'
import RadioGroup from './RadioGroup'
import SearchField from './SearchInput'
import Select from './Select'
import SelectDataSet from './SelectDataSet'
import SingleImageInput from './SingleImageInput'
import TextInput from './TextInput'
import YearSelector from './YearSelector'
const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    IconButton,
    ExportButton,
    Loader,
    MultilineInput,
    CustomSelect,
    SelectDataSet,
    ImageInput,
    ImageAvatar,
    CustomImageAvatar,
    TextInput,
    CustomCircularPercentage,
    DynamicTextInput,
    CustomizedButton,
    CustomFileView,
    ChipButton,
    SearchField,
    AutoCompleteSelect,
    CustomTimePicker,
    CustomRadioGroup,
    AvatarContainer,
    InputPassword,
    FileInput,
    ProgressBar,
    InputNumber,
    QrViewer,
    MultipleImageInput,
    SingleImageInput,
    MultipleSelect,
    MultipleFileInput,
    ModifyButton,
    GpsCoordinatesInput,
    YearSelector
}

export default Controls
