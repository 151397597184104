import React, { useState } from 'react';
import { Checkbox, FormControlLabel, List, ListItem } from '@mui/material';

function MultipleChoiceInput({ options, onChange }) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionClick = (optionLabel) => {
        let updatedOptions;
        if (selectedOptions.includes(optionLabel)) {
            updatedOptions = selectedOptions.filter(item => item !== optionLabel);
        } else {
            updatedOptions = [...selectedOptions, optionLabel];
        }
        setSelectedOptions(updatedOptions);
        onChange(updatedOptions.join(', '));
    };

    return (
        <div>
            <List>
                {options.map((option, index) => (
                    <ListItem key={index} onClick={() => handleOptionClick(option.label)} style={{ cursor: 'pointer' }}>
                        <FormControlLabel
                            control={<Checkbox checked={selectedOptions.includes(option.label)} onChange={() => handleOptionClick(option.label)} />}
                            label={option.label}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default MultipleChoiceInput;
