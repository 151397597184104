import React, { useEffect, useState } from 'react'
import DataImportContext from '../../utils/hooks/DataImportContext'
import { Container, Paper, Typography } from '@mui/material'
import Controls from '../../../../components/Entry/Controls'
import { dataModels } from '../../utils/data/Data'
import TableDetail from '../../../../components/DataTable/TableDetail'
import ApplicationImport from '../../utils/application/Application'
import Notification from '../../../../layouts/Notification'

function ImportContent(props) {
    const models = dataModels
    const app = new ApplicationImport()
    const [needLoad, setNeedLoad] = useState(true)
    const [selectedModel, setSelectedModel] = useState(null)
    const [scanCompleted, setScanCompleted] = useState(true)
    const [scanForeignKeysCompleted, setScanForeignKeysCompleted] = useState(true)
    const [progressValue, setProgressValue] = useState(0)
    const [errors, setErrors] = useState(null)
    const [valueForeignKeyError, setValueForeignKeyError] = useState([])
    const [verificationResult, setVerificationResult] = useState(null)
    const [dataForeignKeyCondition, setDataForeignKeyCondition] = useState(null)
    const [modelsForeignKeys, setModelsForeignKeys] = useState(null)
    const [dataToImport, setDataToImport] = useState(null)
    const [importationLaunched, setImportationLaunched] = useState(false)
    const [importationCompleted, setImportationCompleted] = useState(null)
    const [rawData, setRawData] = useState(null)

    const handleModelChange = (e) => {
        setSelectedModel(models.find((item) => item.tableName === e.target.value))
        reset()
    }

    const reset = () => {
        setErrors(null)
        setValueForeignKeyError([])
        setVerificationResult(null)
        setModelsForeignKeys(null)
        setDataForeignKeyCondition(null)
        setDataToImport(null)
        setImportationLaunched(false)
        setImportationCompleted(null)
        setRawData(null)
    }

    const handeFileChange = (file) => {
        console.log(file)
        app.setFile(file)
        app.generateData()
    }

    const handleFileVerify = (file) => {
        setImportationCompleted('no')
        console.log(selectedModel)
        const modelHeader = selectedModel?.properties.map((item) => item.label)
        const modelKeys = selectedModel?.properties.map((item) => item.id)
        const modelsForeignKeys = selectedModel?.properties.filter((item) => item.isForeignKey)
        setModelsForeignKeys(modelsForeignKeys[0])
        console.log(app)
        const result = app.verifyFileHeader(modelHeader)
        // console.log("resultat d'analyse : ", result)
        // console.log("model's foreign keys : ", modelsForeignKeys)

        setVerificationResult(result)

        if (result === false) {
            setErrors(app.getErrors())
        } else {
            setScanCompleted(false)
            setScanForeignKeysCompleted(false)
            console.log('model keys : ', modelKeys)
            checkData(modelsForeignKeys, modelKeys)
        }
    }

    const handleImportationClick = async () => {
        if (dataToImport) {
            console.log(dataToImport)
            await importData(dataToImport)
        }
    }

    async function importData(dataToImport) {
        console.log('data to import : ', dataToImport)
        setImportationLaunched(true)
        setImportationCompleted('no')
        let dataInserted = []

        for (const data of dataToImport) {
            let category = selectedModel.tableName
            let dataToSend = {
                category: category,
                data: { ...data } // Créer une copie de l'objet 'data'
            }

            console.log('data to send : ', dataToSend)
            try {
                const response = await app.importData(dataToSend)
                dataInserted.push(response)
                setProgressValue((dataInserted.length * 100) / dataToImport.length)
            } catch (error) {
                console.log(error)
            }
        }
        console.log(dataInserted)
        setImportationLaunched(false)
        setImportationCompleted('yes')
        setProgressValue(0)
    }

    async function checkData(data, modelKeys) {
        //données de table de this.getData() de [1] à [this.getData().length - 1]

        console.log(data, modelKeys)
        const dataContent = app.getData()
        const model = selectedModel
        console.log(model)
        if (dataContent === null) {
            setScanCompleted(true)
            setProgressValue(0)
            setVerificationResult(null)
            return
        }
        const header = dataContent[0]
        console.log('header of est : ', header)
        const records = dataContent.slice(1, dataContent.length)
        console.log('records to slice : ', records)
        setDataForeignKeyCondition(data[0].id)
        //set records value row to object with key = header
        const recordsObject = records.map((record, index) => {
            const recordObject = {}
            for (let i = 0; i < header.length; i++) {
                let type = model.properties.find((item) => item.label === header[i])?.type || 'string'
                if (type === 'date') {
                    let date = record[i].split('/')
                    let dateObject = new Date(date[2], date[1] - 1, date[0]).toISOString()
                    recordObject[modelKeys[i]] = dateObject
                } else {
                    recordObject[modelKeys[i]] = record[i]
                }
            }
            return recordObject
        })

        console.log('data objects : ', recordsObject)
        setRawData(recordsObject)
        console.log('data condition : ', data)
        let dataPassed = []
        let dataErrors = []

        for (let dataForeignKey of data) {
            for (let i = 0; i < recordsObject.length; i++) {
                const record = recordsObject[i]
                const responseDataForeignKey = await app.verifyData({
                    category: dataForeignKey.id,
                    record: record,
                    column: dataForeignKey.column_name
                })
                if (responseDataForeignKey) {
                    record[dataForeignKey.table_column] = responseDataForeignKey?.id
                    delete record[dataForeignKey.id]
                    dataPassed.push(record)
                    console.log('data passed : ', dataPassed)
                    setProgressValue((dataPassed.length * 100) / records.length)
                } else {
                    dataErrors.push(record)
                    setValueForeignKeyError((prevData) => [...prevData, record])
                    setProgressValue((dataErrors.length * 100) / records.length)
                }
            }
        }

        setDataToImport(dataPassed)
        setScanForeignKeysCompleted(true)
        setScanCompleted(true)
        setProgressValue(0)
    }

    useEffect(() => {
        if (valueForeignKeyError) {
            // console.log('valueForeignKeyError value : ', valueForeignKeyError)
        }
    }, [valueForeignKeyError])

    return (
        <>
            <DataImportContext needLoad={needLoad} setNeedLoad={setNeedLoad}>
                <Container
                    sx={{
                        padding: '20px 0px 20px 0px'
                    }}
                >
                    <Controls.AutoCompleteSelect
                        label="Modèle de données"
                        name="model"
                        options={models || []}
                        labelKey="label"
                        valueKey="tableName"
                        onChange={handleModelChange}
                        value={selectedModel?.tableName}
                    />
                    {selectedModel && (
                        <>
                            <Typography
                                sx={{
                                    padding: `10px 0px 0px 0px `,
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#000000',
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                Format xlsx
                            </Typography>
                            <TableDetail
                                headCells={selectedModel?.properties}
                                hideFilterButton={true}
                                data={[]}
                                title={''}
                                needData={false}
                            />
                            <Typography
                                sx={{
                                    padding: `10px 0px 20px 0px `,
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#000000',
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                Choisir fichier à importer
                            </Typography>
                            <Controls.FileInput
                                onChooseFile={reset}
                                onClick={handleFileVerify}
                                onChange={handeFileChange}
                                label={'Vérifier'}
                                type="xlsx"
                                processCompleted={scanCompleted}
                            />
                            {verificationResult !== null && (
                                <Paper
                                    sx={{
                                        marginTop: '10px',
                                        padding: '10px 10px 10px 10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            padding: `10px 0px 20px 0px `,
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            boxShadow: 'none',
                                            color: '#000000',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Résultat de l'analyse de fichier
                                    </Typography>
                                    <Container>
                                        <Typography
                                            sx={{
                                                padding: `10px 0px 20px 0px `,
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                boxShadow: 'none',
                                                color: '#000000',
                                                fontSize: '0.7rem',
                                                display: 'flex'
                                            }}
                                        >
                                            Entête(s) non connue(s) :
                                            {errors?.headersNotEquals ? (
                                                <i>{errors?.headersNotEquals.map((item) => item).join(', ')}</i>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        color: '#0FCB8F',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {' '}
                                                    Pass
                                                </Typography>
                                            )}
                                        </Typography>
                                        {!errors?.headersNotEquals && (
                                            <>
                                                <Typography
                                                    sx={{
                                                        padding: `10px 0px 20px 0px `,
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        color: '#000000',
                                                        fontSize: '0.7rem'
                                                    }}
                                                >
                                                    Vérification des clés étrangères :
                                                    {!scanForeignKeysCompleted ? (
                                                        <>
                                                            <Controls.ProgressBar progress={progressValue} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {valueForeignKeyError.length !== 0 ? (
                                                                <Typography
                                                                    sx={{
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',
                                                                        boxShadow: 'none',

                                                                        color: 'red',
                                                                        fontSize: '0.8rem'
                                                                    }}
                                                                >
                                                                    <i>
                                                                        {valueForeignKeyError.map((item, index) => {
                                                                            console.log(rawData)
                                                                            return (
                                                                                <div key={index}>
                                                                                    Le {modelsForeignKeys.label}{' '}
                                                                                    {item[`${dataForeignKeyCondition}`]}{' '}
                                                                                    (ligne{' '}
                                                                                    {rawData?.indexOf(
                                                                                        rawData.find(
                                                                                            (record) =>
                                                                                                record[
                                                                                                    `${dataForeignKeyCondition}`
                                                                                                ] ===
                                                                                                item[
                                                                                                    `${dataForeignKeyCondition}`
                                                                                                ]
                                                                                        )
                                                                                    ) + 2}
                                                                                    ) n'existe pas
                                                                                </div>
                                                                            )
                                                                        })}{' '}
                                                                        <br />
                                                                    </i>
                                                                </Typography>
                                                            ) : (
                                                                <Typography
                                                                    sx={{
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        color: '#0FCB8F',
                                                                        fontSize: '0.8rem'
                                                                    }}
                                                                >
                                                                    Pass
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </Typography>
                                            </>
                                        )}
                                    </Container>
                                    {valueForeignKeyError.length === 0 &&
                                        importationCompleted !== 'yes' &&
                                        !errors?.headersNotEquals && (
                                            <Container>
                                                <Controls.Button
                                                    text="Lancer l'importation"
                                                    onClick={handleImportationClick}
                                                />
                                                {importationLaunched && (
                                                    <Controls.ProgressBar progress={progressValue} />
                                                )}
                                            </Container>
                                        )}
                                    {importationCompleted === 'yes' && (
                                        <Container>
                                            <Typography
                                                sx={{
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    color: '#0FCB8F'
                                                }}
                                            >
                                                Importation terminée.
                                            </Typography>
                                        </Container>
                                    )}
                                </Paper>
                            )}
                        </>
                    )}
                </Container>
            </DataImportContext>
        </>
    )
}

export default ImportContent
