// import { Http } from '../../../applications/Http.js'

import moment from 'moment'
import { Http } from '../../../applications/Http'
import Service from '../../../applications/Service'
import Notification from '../../../layouts/Notification'

class PlantingService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plantation')
        return this
    }

    static async getByCode(id) {
        try {
            return await Http.get([this.url, id].join('/'), {})
        } catch (error) {
            console.log(error)
            new Notification(this.messages.update_error, 0)
        }
    }

    static async addPlanting(data) {
        try {
            data.planting_date_begin = moment(data.planting_date_begin).format()
            data.planting_date_end = moment(data.planting_date_end).format()
            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Notification(this.messages.create_error, 0)
        }
    }

    static async updatePlanting(id, data) {
        try {
            data.planting_date_begin = moment(data.planting_date_begin).format()
            data.planting_date_end = moment(data.planting_date_end).format()
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }

    static async getAllPlanting() {
        try {
            const response = await Http.get('/api/plantation')
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }

    static async importTrackingPoints(data) {
        try {
            const response = await Http.post([this.url, 'import-tracking-points'].join('/'), data)
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getPlantingTrackingPoints(fokontany){
        try {
            const response = await Http.get([this.url, 'tracking-points', fokontany].join('/'))
            console.log(response.data)
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
}

export default PlantingService
