import React, { useState } from 'react'
import { useAuth } from '../../applications/hooks/UseAuth'
import PageHeader from '../../components/Typography/PageHeader'
import { Form, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import Controls from '../../components/Entry/Controls'
import PlantingService from './services/Planting'
import PlantingList from './components/PlantingList'
import PlantingView from './components/PlantingView'

const CURRENT_PAGE = 'agroforestry_plantation'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

function Planting() {
    const classes = useStyles()

    const service = PlantingService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true

    const navigate = useNavigate()

    const addAction = () => {
        navigate('/app/mangrove/planting/new')
    }

    return (
        <>
            <PageHeader title={'Gestion des plantations'} subTitle={'Gestion plantation'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <Controls.Button text="Nouveau" variant="contained" size="large" onClick={addAction} />
                </div>
            </PageHeader>
            {/* <MuiToolbar style={{ padding: 0, margin: '20px 0 0 0' }} className={classes.siteChange}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    <BottomNavigationAction key="1" label="Plate-bande" value="plat" />
                    <BottomNavigationAction key="2" label="Suivi des jeunes plants" value="tracking" />
                    <BottomNavigationAction key="3" label="Sortie en pépinière" value="outing" />
                </BottomNavigation>
            </MuiToolbar> */}
            <PlantingList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={PlantingView}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default Planting
