import React, { useEffect, useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import { Container } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const { Input } = Controls

function CollectionPeasant({ data, onChange, label }) {
    const [peasantData, setPeasantData] = useState(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPeasant, setSelectedPeasant] = useState(null);

    useEffect(() => {
        setPeasantData(data);
    }, [data]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        const filteredData = data.filter(peasant =>
            peasant.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
            peasant.lastname.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setPeasantData(filteredData);
    };

    const handleItemClick = (peasant) => {
        setSelectedPeasant(peasant);
        setSearchTerm(`${peasant.firstname} ${peasant.lastname}`);
        
        onChange({ peasant_firstname: peasant.firstname, peasant_lastname: peasant.lastname });
    };

    return (
        <>
            <Input label={label} value={searchTerm} onChange={handleInputChange} />

            <Container
            sx={{
                display: 'inline-block',
                maxWidth: '100%',
                overflowY: 'scroll',
                maxHeight: '250px',
                overflowX: 'hidden'
            }}
            >
                <List>
                    {peasantData.map((peasant, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleItemClick(peasant)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <ListItemText primary={`${peasant.firstname} ${peasant.lastname}`} />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </>
    );
}

export default CollectionPeasant;
