import React, { useEffect, useState } from 'react'
import { Grid, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Vegetation from './VegetationsPlanted'
import Controls from '../../../../../components/Entry/Controls'
import { useForm } from '../../../../../applications/hooks/UseForm'
import PlotService from '../../../services/Plots'
import Notification from '../../../../../layouts/Notification'
import { useAuth } from '../../../../../applications/hooks/UseAuth'

const { Input: TextField } = Controls

const useStyles = makeStyles((theme) => ({
    columnForm: {
        padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: 'none',
        boxShadow: 'none',
        '& .MuiTextField-root': {
            marginBottom: '40px',
            marginLeft: '0px'
        },
        '& .MuiPaper-root': {
            margin: `0 0 ${theme.spacing(4)} 0 `,
            backgroundColor: '#f8f8f8',
            border: 'none',
            boxShadow: 'none'
        }
    }
}))

const initialValues = {}

function FirstFormView(props) {
    const { records, setNeedLoad } = props
    const classes = useStyles()
    const { user } = useAuth()
    const [userHaveToModify, setUserHaveToModify] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if ('group' in fieldValues) temp.group = fieldValues.group.length !== 0 ? '' : 'Ce champ est requis.'
        if ('genre' in fieldValues) temp.genre = fieldValues.genre.length !== 0 ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    useEffect(() => {
        if (records) {
            setValues({ ...records })
        }
    }, [records])

    const handleModify = () => {
        setUserHaveToModify(false)
        const {
            plantationSpecies,
            plantation,
            photos,
            waypoint,
            regions,
            district,
            commune,
            fkt,
            quadrillage,
            village,
            plot_code,
            peasant_code,
            ...res
        } = values
        console.log(res)
        try {
            setNeedLoad(true)
            const service = PlotService.load()
            service
                .update(plot_code, {
                    ...res,
                    estimated_number_plant: parseInt(values.estimated_number_plant),
                    userHistory: [user.firstname, user.lastname].join(' ')
                })
                .then((result) => {
                    if (result) {
                        console.log(result)
                        new Notification(service.messages.update_success, 1)
                        setNeedLoad(false)
                    }
                })
        } catch (error) {
            setUserHaveToPost(false)
            console.log(error)
        }
    }

    return (
        <>
            <Controls.ModifyButton
                onClick={() => setUserHaveToModify(!userHaveToModify)}
                userHaveToModify={userHaveToModify}
            />
            <Grid container>
                <Grid item xs={12}>
                    <h1 className="font-semibold mb-[20px]">Délimitation administrative</h1>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        {/* <TextField
                            isDisabled={true}
                            size="small"
                            name="peasant_code"
                            label="Code paysan"
                            value={values?.peasant_code || ''}
                            onChange={handleInputChange}
                        /> */}
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="region"
                            label="Région"
                            value={values?.regions || ''}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="commune"
                            label="Commune"
                            value={values?.commune || ''}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Village"
                            name="village"
                            value={values?.village || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="district"
                            label="District"
                            value={values?.district || ''}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="fkt"
                            label="Fokontany"
                            value={values?.fkt || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <h1 className="font-semibold mb-[20px]">Informations sur la parcelle</h1>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        {/* <TextField
                            isDisabled={true}
                            size="small"
                            name="peasant_code"
                            label="Code paysan"
                            value={values?.peasant_code || ''}
                            onChange={handleInputChange}
                        /> */}
                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            label="Statut foncier"
                            name="statut_foncier"
                            value={values?.statut_foncier || ''}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            label="Surface (m2)"
                            name="surface"
                            type="number"
                            value={values?.surface || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="waypoint"
                            label="Waypoint"
                            value={values?.waypoint || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <h1 className="font-semibold mb-[20px]">Informations techniques</h1>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            label="Topo sequence"
                            name="topo"
                            value={values?.topo || ''}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            label="Utilisation actuelle de la parcelle"
                            name="use_actually"
                            value={values?.use_actually || ''}
                            onChange={handleInputChange}
                        />

                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            label="Estimation nombre d'arbres sur la parcelle"
                            name="estimated_number_plant"
                            value={values?.estimated_number_plant || ''}
                            type="number"
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.columnForm}>
                        <TextField
                            isDisabled={!userHaveToModify}
                            size="small"
                            name="texture"
                            label="Texture"
                            value={values?.texture || ''}
                            onChange={handleInputChange}
                        />
                        <Paper>
                            <Vegetation plantedSpecies={values?.plantationSpecies} />
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {userHaveToModify && (
                <div className="flex justify-end items-end m-5 p-5">
                    <Controls.Button
                        text="Modifier"
                        onClick={handleModify}
                        haveSpin={true}
                        color="primary"
                        disabled={userHaveToPost}
                    />
                </div>
            )}
        </>
    )
}

export default FirstFormView
