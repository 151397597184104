import React, { useContext, useEffect, useState } from 'react'
import { ContextForm } from '../../utils/FormContext'
import { Form } from '../../../../applications/hooks/UseForm'
import Loader from '../../../../components/Entry/Loading'
import { Container } from '@mui/material'
import InputData from './InputData.jsx'

function AddDataForm(props) {
    const { openPopup } = props
    const dataForm = useContext(ContextForm)
    const [loading, setLoading] = useState(false)
    const [dataColumn, setDataColumn] = useState([])

    useEffect(() => {
        setLoading(true)
        if (dataForm?.form !== 'undefined') {
            if (typeof dataForm.form === 'string') {
                const data = JSON.parse(dataForm.form)
                console.log('data', data.data)
                setDataColumn(data.data)
                setLoading(false)
            }
        }
    }, [dataForm.form])

    return (
        <>
            <Loader isLoading={loading} />
            <Form>
                <Container>
                    {dataColumn && (
                        <>
                            <InputData data={dataColumn} options={dataForm} openPopup={openPopup} />
                        </>
                    )}
                </Container>
            </Form>
        </>
    )
}

export default AddDataForm
