import React from 'react'

function ContentListViewer(props) {
    const { values, height = null } = props
    return (
        <div
            style={{
                overflowY: 'scroll',
                height: height,
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                scrollbarWidth: 'thin',
                scrollbarColor: '#d0e0d7 #E7F2EE',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                    background: '#d0e0d7'
                }
            }}
        >
            {values?.map((item, key) => {
                return (
                    <div
                        key={key}
                        style={{
                            marginBottom: '10px',
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '5px'
                        }}
                    >
                        <b>{item.title}</b>
                        {item.contents.map((content, index) => {
                            return (
                                <div key={index}>
                                    {content.label} : {content.value}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default ContentListViewer
