import React from 'react'
import Table from '../../../components/DataTable/Table'
import { useLocation } from 'react-router-dom'

function OrderTrackingList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad, drawer } = props
    const location = useLocation()
    const path = location.pathname.split('/')
    const dataId = location.pathname.split('/').pop()

    console.log('path', path[2])

    let headCells = [
        { id: 'date', label: 'Date ', type: 'date' },
        { id: 'code', label: 'Référence ' },
        { id: 'customer', label: 'Client' },
        { id: 'type', label: 'Type' },
        { id: 'location', label: 'Localisation demande' },
        { id: 'tree_ordered', label: 'Nombre arbres commandés' },
        { id: 'state', label: 'Etat' }
    ]

    if (path[2] === 'agroforestry') {
        headCells = [
            ...headCells,
            {
                id: 'actions',
                type: 'actions',
                label: 'Actions',
                disableSorting: true,
                showEdit: false,
                showRemove: false,
                showView: '/app/agroforestry/order/view',
                forcePassId: true
            }
        ]
    } else if (path[2] === 'mangrove') {
        headCells = [
            ...headCells,
            {
                id: 'actions',
                type: 'actions',
                label: 'Actions',
                disableSorting: true,
                showEdit: false,
                showRemove: false,
                showView: '/app/mangrove/order/view',
                forcePassId: true
            }
        ]
    } else {
        headCells = [
            ...headCells,
            {
                id: 'actions',
                type: 'actions',
                label: 'Actions',
                disableSorting: true,
                showEdit: false,
                showRemove: true,
                showView: '/app/commercial/order/view',
                forcePassId: true
            }
        ]
    }

    return (
        <>
            <Table
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                title={`Visualisation commande`}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                // showSiteButton={path[2] === 'agroforestry' ? true : false}
                showSiteButton={false}
                // showMangroveSiteButton={path[2] === 'mangrove' ? true : false}
                showMangroveSiteButton={false}
                à
                décommenter
            />
        </>
    )
}

export default OrderTrackingList
