import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'
import DataSetsService from '../../datasets/services/DataSets'
import SelectDataSet from '../../../components/Entry/SelectDataSet'
import CustomSelect from '../../../components/Entry/CustomSelect'
import CustomerOrderService from '../../customer_order/services/CustomerOrder'
import ProvinceService from '../../provinces/services/Provinces'
import { TiDelete } from 'react-icons/ti'
import PlotService from '../../plot/services/Plots'
import PlatDrainService from '../../nursery_mangrove/plat_drain/services/plat_drain'
import MangroOrderItemsService from '../services/MangroOrderItems'
import OutingService from '../../nursery_mangrove/outing/services/outing'
import Drawer from '../../../layouts/Drawer'

const { Input: TextField } = Controls

const initialFValues = {
    customers: '',
    type: '',
    site_id: '',
    tree_ordered: '',
    location: '',
    plot_id: '',
    getout_date: '',
    commands: []
}

const types = [{ label: 'Agroforesterie' }, { label: 'Mangroves' }]

export const OrderTrackingFormMangrove = (props) => {
    const { records } = props

    const location = useLocation()
    const path = location.pathname.split('/').pop()
    const showBtnCommands = location.pathname.includes('commercial')
    const navigate = useNavigate()
    const dataset = DataSetsService.load()
    const customer_api = CustomerOrderService.load()
    const site_api = ProvinceService.load()
    const plot_api = PlotService.load()
    const order_item_api = MangroOrderItemsService.load()

    const [dataState, setDataState] = useState([])
    const [customer, setCustomer] = useState([])
    const [mangrosite, setMangroSite] = useState([])
    const [agrosite, setAgroSite] = useState([])
    const [sites, setSites] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)

    const [plots, setPlots] = useState([])
    const [platDrain, setPlatDrain] = useState([])
    const [orders, setOrders] = useState([])
    const [siteNames, setSiteNames] = useState({})
    const [dynamicFields, setDynamicFields] = useState([
        { plat_drain_id: '', quantity_plat_command: '', quantity_plat_drain: '' }
    ])

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                customers: records?.customers?.id || '',
                type: records?.type || '',
                site_id: records?.site_id || '',
                plot_id: records?.plot_id || '',
                tree_ordered: records?.tree_ordered || '',
                location: records?.location || '',
                getout_date: records?.getout_date || '',
                commands: records?.commands || initialFValues.commands
            }))
        }
    }, [records])

    useEffect(() => {
        customer_api.getCustomerOrder().then((res) => {
            setCustomer(res)
        })
        site_api.getAgroSites().then((res) => {
            setAgroSite(res?.data)
        })
        dataset
            .getDataCode('statut_commande')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    useEffect(() => {
        const siteNamesObj = {}
        sites.forEach((site) => {
            siteNamesObj[site.id] = site.name
        })
        setSiteNames(siteNamesObj)
    }, [sites])

    useEffect(() => {
        if (records?.site_id) {
            PlatDrainService.getBySiteId(records.site_id)
                .then((res) => {
                    setPlatDrain(res)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des pépinières :', error)
                })
        }
        if (records?.id) {
            order_item_api
                .getMangroOrderItemByOrderId(records.id)
                .then((res) => {
                    setOrders(res)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des pépinières :', error)
                })
        }
    }, [records])

    useEffect(() => {
        plot_api
            .getAllPlots()
            .then((users) => {
                if (users) {
                    setPlots(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        if (orders) {
            const updatedCommands = orders.map((order) => ({
                plat_drain_id: order.plat_drain_id,
                quantity_plat_command: order.quantity_plat_command,
                species: order.species?.name,
                quantity_plat_drain: order.quantity_plat_drain,
                isDisabled: true
            }))
            setDynamicFields(updatedCommands)
        }
    }, [orders])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('site_id' in fieldValues) temp.site_id = fieldValues.site_id ? '' : 'Ce champ est requis.'
        if ('type' in fieldValues) temp.type = fieldValues.type ? '' : 'Ce champ est requis.'
        if ('tree_ordered' in fieldValues) temp.tree_ordered = fieldValues.tree_ordered ? '' : 'Ce champ est requis.'
        if ('customers' in fieldValues)
            temp.customers = fieldValues.customers.length !== 0 ? '' : 'Ce champ est requis.'

        setErrors({ ...temp })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                console.log('records dans commande : ',records)
                if (records?.id) {
                    const commandsData = dynamicFields.filter((command) => !command.isDisabled)

                    for (const field of commandsData) {
                        const order_item = {
                            order_id: parseInt(records.id),
                            plat_drain_id: parseInt(field.plat_drain_id),
                            specie_id: parseInt(field.specie_id),
                            quantity_plat_command: parseInt(field.quantity_plat_command),
                            quantity_plat_drain: parseInt(field.quantity_plat_drain)
                        }

                        const response = await MangroOrderItemsService.createMangroOrderItem(order_item)
                        if (response) {
                            new Notification('Commande effectuée avec succès', 1)
                        }
                    }
                    for (const field of commandsData) {
                        const order_item = {
                            plat_drain_id: parseInt(field.plat_drain_id),
                            specie_id: parseInt(field.specie_id),
                            nbr_output: parseInt(field.quantity_plat_command)
                        }

                        const response = await OutingService.addOuting(order_item)
                        if (response) {
                            new Notification('Sortie effectuée avec succès', 1)
                        }
                    }
                }
            } catch (error) {
                console.error("Une erreur s'est produite lors de l'envoi des données :", error)
            }
        }
    }

    const handlePlatDrainChange = (index, e) => {
        const selectedId = e.target.value
        const selectedPlatDrain = platDrain.find((pd) => pd.id === parseInt(selectedId))
        const newDynamicFields = [...dynamicFields]
        newDynamicFields[index] = {
            // ...newDynamicFields[index],
            plat_drain_id: selectedId,
            species: selectedPlatDrain?.species || '',
            specie_id: selectedPlatDrain?.specieId || '',
            quantity_plat_drain: selectedPlatDrain?.quantity || ''
        }
        setDynamicFields(newDynamicFields)
    }

    const handleQuantityChange = (index, e) => {
        const newDynamicFields = [...dynamicFields]
        newDynamicFields[index].quantity_plat_command = +e.target.value
        setDynamicFields(newDynamicFields)
    }

    const addField = () => {
        setDynamicFields([...dynamicFields, { plat_drain_id: '', quantity_plat_command: '' }])
    }

    const removeField = (index) => {
        const newDynamicFields = [...dynamicFields]
        newDynamicFields.splice(index, 1)
        setDynamicFields(newDynamicFields)
    }

    const totalOrderedTrees = dynamicFields?.reduce((acc, command) => {
        return acc + command.quantity_plat_command
    }, 0)

    const totalOrderedTreesSave = orders?.reduce((acc, command) => {
        return acc + command.quantity_plat_command
    }, 0)

    const tree_needed = totalOrderedTrees >= records?.tree_ordered
    const tree_needed_save = totalOrderedTreesSave >= records?.tree_ordered

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div>
                    <div className="flex p-4">
                        <div className="w-full p-2">
                            <>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <CustomSelect
                                            label="Client"
                                            name="customers"
                                            value={values?.customers || ''}
                                            options={customer}
                                            error={errors.customers}
                                            labelKey="firstname"
                                            valueKey="id"
                                            isDisabled={path !== 'new'}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <SelectDataSet
                                            label="Type"
                                            value={values.type || ''}
                                            name="type"
                                            options={types}
                                            error={errors?.type}
                                            isDisabled={path !== 'new'}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        {path !== 'new' ? (
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                name="location"
                                                value={values?.location}
                                                size="small"
                                                label="Localisation demandée"
                                                disabled
                                            />
                                        ) : (
                                            <CustomSelect
                                                label="Localisation demandée"
                                                name="site_id"
                                                value={values?.site_id || ''}
                                                options={sites}
                                                error={errors.site_id}
                                                labelKey="name"
                                                valueKey="id"
                                                onChange={handleInputChange}
                                            />
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="tree_ordered"
                                            value={values?.tree_ordered}
                                            size="small"
                                            label="Arbre commandés"
                                            error={errors.tree_ordered}
                                            disabled={path !== 'new'}
                                            type="number"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>

                    {path !== 'new' && (
                        <div className="border border-gray-300 rounded-lg m-4 p-2">
                            <div className="flex justify-between ml-[20px] font-semibold italic">
                                <p>Plate Bande</p>
                                <p>Espèces</p>
                                <p className="mr-[100px]">Quantité</p>
                            </div>

                            {dynamicFields.map((field, index) => (
                                <div className="p-3" key={field.id}>
                                    <div className="w-full p-2">
                                        <div className="flex gap-[40px] mb-[40px]">
                                            <div className="w-[40%]">
                                                <CustomSelect
                                                    name="plat_drain_id"
                                                    options={platDrain}
                                                    labelKey="code"
                                                    valueKey="id"
                                                    value={field.plat_drain_id}
                                                    onChange={(e) => handlePlatDrainChange(index, e)}
                                                    isDisabled={field?.isDisabled}
                                                />
                                            </div>

                                            <div className="w-[80%]">
                                                <div className="flex gap-[60px]">
                                                    <div className="w-full mt-[16px]">
                                                        {field.species && (
                                                            <div className="flex justify-between p-2 min-h-[40px]">
                                                                <p>{field.species}</p>
                                                                <p>{field.quantity_plat_drain}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="w-full">
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            name="quantity_plat_command"
                                                            value={field.quantity_plat_command}
                                                            onChange={(e) => handleQuantityChange(index, e)}
                                                            disabled={field?.isDisabled}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cursor-pointer italic" onClick={() => removeField(index)}>
                                                {field?.isDisabled !== true && (
                                                    <TiDelete className="text-red-500 w-8 h-8" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div>
                                {!tree_needed && (
                                    <div>
                                        {!showBtnCommands && (
                                            <p className="mb-4 cursor-pointer italic text-lime-500" onClick={addField}>
                                                Ajouter
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!tree_needed_save && (
                                <div>
                                    {!showBtnCommands && (
                                        <div className="flex justify-end items-end m-5 p-5">
                                            <Controls.Button type="submit" text="Enregistrer" />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex justify-end items-end m-5 p-5">
                        {path === 'new' && <Controls.Button type="submit" text="Enregistrer" />}
                    </div>
                </div>
            </Form>
        </div>
    )
}
