import React, { useState } from 'react'
import Controls from '../../../../components/Entry/Controls'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Popup from '../../../../layouts/Popup'
import FormContext from '../../utils/FormContext'
import AddDataForm from './AddDataForm'

const PreviewButton = (props) => {
    const { code, title } = props
    const [needLoad, setNeedLoad] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)

    const handleForm = () => {
        setOpenPopup(true)
        setNeedLoad(true)
    }

    return (
        <>
            <Controls.ActionButton onClick={handleForm}>
                <VisibilityIcon fontSize="small" />
            </Controls.ActionButton>

            {openPopup && (
                <FormContext code={code} needLoad={needLoad}>
                    <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                        <AddDataForm openPopup={setOpenPopup} />
                    </Popup>
                </FormContext>
            )}
        </>
    )
}

export default PreviewButton
