// import { Http } from '../../../applications/Http.js'
import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class MangroOrderItemsService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création avec succès.',
        create_error: 'Erreur de création.',
        get_success: 'Récupération réussie.',
        get_error: 'Erreur de récupération.'
    }

    static load() {
        this.setUrl('/api/order_tracking/mangro_order_items')
        return this
    }

    static async getAllMangroOrderItems() {
        try {
            const response = await Http.get(this.url)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des éléments d'ordre :", error)
            throw error
        }
    }

    static async createMangroOrderItem(data) {
        try {
            const response = await Http.put('/api/order_tracking/mangro_order_items', data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de la création de l'élément d'ordre.")
        }
    }

    static async getMangroOrderItemByOrderId(orderId) {
        try {
            const response = await Http.get([this.url, orderId].join('/'))
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de la récupération de l'élément d'ordre par ID.")
        }
    }
}

export default MangroOrderItemsService
