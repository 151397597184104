import { configureStore } from '@reduxjs/toolkit'
import headerSlice from './reducers/HeaderSlice'
import AuthSlice from './reducers/AuthSlice'

const combinedReducer = {
    header: headerSlice,
    auth: AuthSlice,
}

export default configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    devTools: true
})
