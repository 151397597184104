import React, { useCallback, useEffect, useState } from 'react'
import Controls from '../../../../components/Entry/Controls'
import Loader from '../../../../components/Entry/Loading'
import { Container } from '@mui/material'
import LocationAgroGroup from './form/LocationAgroGroup'
import LocationAgroLocation from './form/LocationAgroLocation'
import DataSetInput from './form/DataSetInput'
import CollectionInput from './form/CollectionInput'
import { isObject } from '../../utils/function/DataSet'
import MultipleChoiceInput from './form/MultipleChoiceInput'
import SingleChoiceInput from './form/SingleChoiceInput'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import FormService from '../../services/Form'
import DateSetInput from './form/DateSetInput'
import Photo from './form/Photo'

const { Input, Button } = Controls

function InputOptions({ data, onChange, value }) {
    if (data) {
        switch (data.typeLabel) {
            case 'Photo':
                const handleChangePhoto = (e) => {
                    onChange(e.target.content)
                }
                return <Photo  onChange={handleChangePhoto} value={value} />

            case 'Réponse courte':
                const handleChange = (e) => {
                    onChange(e.target.value)
                }
                return <Input label={data.question} onChange={handleChange} value={value} />

            case 'Collections':
                return (
                    <>
                        {data && (
                            <>
                                <CollectionInput data={data} onChange={onChange} label={data.question} />
                            </>
                        )}
                    </>
                )

            case 'Données de base':
                return (
                    <>
                        <DataSetInput data={data} onChange={onChange} value={value} />
                    </>
                )

            case 'Location':
                if (data.options[0].value === 'location/agro_group') {
                    return (
                        <>
                            <LocationAgroGroup data={data} onChange={onChange} />
                        </>
                    )
                } else if (data.options[0].value === 'location/agro_location') {
                    return (
                        <>
                            <LocationAgroLocation data={data} onChange={onChange} />
                        </>
                    )
                }
                return <Input label={data.options[0].label} onChange={onChange} value={value} />

            case 'Map':
                let handleMapChange = (e) => {
                    onChange(e.target.value)
                }
                return <Input label={data.question} onChange={handleMapChange} value={value} />

            case 'Date':
                return <DateSetInput data={data} onChange={onChange} value={value} />
            case 'Paragraphe':
                let handleParagrapheChange = (e) => {
                    onChange(e.target.value)
                }
                return <Input label={data.question} onChange={handleParagrapheChange} multiline={true} value={value} />

            case 'Choix multiples':
                return (
                    <>
                        <p className="text-lg font-semibold">{data.question}</p>
                        <MultipleChoiceInput options={data.options} onChange={onChange} />
                    </>
                )

            case 'Choix unique':
                return (
                    <>
                        <p className="text-lg font-semibold">{data.question}</p>
                        <SingleChoiceInput options={data.options} onChange={onChange} />
                    </>
                )

            default:
                break
        }
    }
    return null
}

function InputData(props) {
    const { data, options, openPopup } = props
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [dataSet, setDataSet] = useState([])
    const [inputObject, setInputObject] = useState()
    const [buttonAction, setButtonAction] = useState('Suivant')
    const [index, setIndex] = useState(0)
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = (e) => {
        if (typeof e === 'number' || typeof e === 'string' || Array.isArray(e) || isObject(e)) {
            setInputValue(e)
        } else if (e) {
            setInputValue(e.target.value)
        }
    }

    const handleNextButton = useCallback(() => {
        if (index <= data.length) {
            if (isObject(inputValue) && !Array.isArray(inputValue)) {
                const array = Object.entries(inputValue).map(([code, value]) => ({ code, value }))
                setDataSet((prevData) => [...prevData, ...array])
            } else {
                setDataSet((prevData) => [...prevData, { code: data[index]?.code, value: inputValue }])
            }
            setInputValue('')
            setIndex(index + 1)
        }
    }, [index, data, inputValue])

    const handleSaveButton = () => {
        const transformedData = dataSet.reduce((acc, { code, value }) => {
            let transformedValue = value
            if (Array.isArray(value)) {
                // Map chaque élément du tableau pour créer un nouvel objet avec les clés 'name' et 'nombre'
                transformedValue = value.map((item) => ({
                    name: item.name, // Assurez-vous que 'name' est une clé valide dans chaque élément de 'value'
                    nombre: item.number // Assurez-vous que 'nombre' est une clé valide dans chaque élément de 'value'
                }))
            }

            // try {
            //     const parsedValue = JSON.parse(transformedValue)
            //     return { ...acc, [code]: parsedValue }
            // } catch (error) {
            return { ...acc, [code]: transformedValue }
            // }
        }, {})

        const dataSend = [
            {
                type: 'add',
                form: options.code,
                technicien: `${user.firstname} ${user.lastname}`,
                data: transformedData
            }
        ]

        FormService.sendData(dataSend)
        openPopup(false)
    }

    useEffect(() => {
        setLoading(true)
        if (data.length > 0) {
            setInputObject(() => <InputOptions data={data[index]} onChange={handleInputChange} value={inputValue} />)
            if (index === data.length) {
                setButtonAction('Enregistrer')
            }
            setLoading(false)
        }
    }, [data, index, dataSet, inputValue])

    return (
        <>
            <Loader isLoading={loading} />
            <Container sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>{inputObject}</Container>
            {buttonAction === 'Suivant' && <Button text="Suivant" onClick={handleNextButton} />}
            {buttonAction === 'Enregistrer' && <Button text="Enregistrer" onClick={handleSaveButton} />}
        </>
    )
}

export default InputData
