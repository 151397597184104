import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'

import CustomerOrderService from '../services/CustomerOrder'
import DataSetsService from '../../datasets/services/DataSets'
import SelectDataSet from '../../../components/Entry/SelectDataSet'

const { Input: TextField } = Controls

const initialFValues = {
    firstname: '',
    lastname: '',
    state: '',
    email: '',
    place: '',
    phone: ''
}

export const CustomerOrderForm = (props) => {
    const { records } = props

    const navigate = useNavigate()
    const dataset = DataSetsService.load()

    const [dataState, setDataState] = useState([])

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                firstname: records?.firstname || '',
                lastname: records?.lastname || '',
                state: records?.state || '',
                email: records?.email || '',
                place: records?.place || '',
                phone: records?.phone || ''
            }))
        }
    }, [records])

    useEffect(() => {
        dataset
            .getDataCode('statut_customer')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('email' in fieldValues) temp.email = fieldValues.email ? '' : 'Ce champ est requis.'
        if ('place' in fieldValues) temp.place = fieldValues.place ? '' : 'Ce champ est requis.'
        if ('phone' in fieldValues) temp.phone = fieldValues.phone ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            const dataToSend = { ...values }
            let customer = null
            let message = ''
            if (records?.id) {
                customer = await CustomerOrderService.updateCustomerOrder(records.id, dataToSend)
                message = CustomerOrderService.messages.update_success
            } else {
                customer = await CustomerOrderService.addCustomerOrder(dataToSend)
                message = CustomerOrderService.messages.create_success
            }

            if (customer) {
                new Notification(message, 1)
                navigate(-1)
            } else {
                new Notification(message, 0)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div>
                    <div className="flex p-4">
                        <div className="w-full p-2">
                            <>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Nom"
                                            name="firstname"
                                            value={values?.firstname || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.firstname}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Prénom"
                                            name="lastname"
                                            value={values?.lastname || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.lastname}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <SelectDataSet
                                            label="Statut"
                                            value={values.state || ''}
                                            onChange={handleChange}
                                            name="state"
                                            options={dataState}
                                            error={errors?.state}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Téléphone"
                                            name="phone"
                                            value={values?.phone || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.phone}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Email"
                                            name="email"
                                            value={values?.email || ''}
                                            size="small"
                                            isRequired={true}
                                            onChange={handleChange}
                                            error={errors?.email}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Lieux"
                                            name="place"
                                            value={values?.place || ''}
                                            size="small"
                                            onChange={handleChange}
                                            error={errors?.place}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                    <div className="flex justify-end items-end m-5 p-5">
                        <Controls.Button type="submit" text="Enregistrer" />
                    </div>
                </div>
            </Form>
        </div>
    )
}
