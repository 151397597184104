import React, { useEffect, useState } from 'react'
import CustomDropdown from '../navigate/CustomDropdown'
import { Container, Grid, Paper, Typography } from '@mui/material'
import Controls from '../../../../../../../../components/Entry/Controls'
import { Form, useForm } from '../../../../../../../../applications/hooks/UseForm'
import { addOrEdit, update } from '../../../../../../../../applications/UtilFunctions'
import NurserySemisService from '../../../../../../services/NurserySemis'
import { useAuth } from '../../../../../../../../applications/hooks/UseAuth'

const initialValues = {
    SemisDate: new Date().toISOString(),
    SemisType: '',
    quantity: '',
    unity: '',
    pot_number: '',
    pot_size: '',
    // first_germination_date: new Date().toISOString(),
    nursery_id: 0,
    specie_id: ''
}

function SemiFormView(props) {
    const {
        styles,
        dataSemis,
        dataSemisUnity,
        dataSemisType = [],
        nursery_id,
        setNeedLoad,
        dataSemisSpecies = [],
        setSemisSelected,
        dataPotSizes = [],
        setSupplementData,
        setSelectedSemisOnAdd,
        specieId,
        userHavingToAdd,
        setUserHavingToAdd,
    } = props
    const [inputDisabled, setInputDisabled] = useState(true)
    const [dataSemisSelected, setDataSemisSelected] = useState(null)
    const [disableGerminationDate, setDisableGerminationDate] = useState(true)
    const [first_germination_date, setFirst_germination_date] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const {user} = useAuth()

    const service = NurserySemisService.load()

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('SemisDate' in fieldValues) temp.SemisDate = fieldValues.SemisDate ? '' : 'Ce champ est requis.'
        if ('SemisType' in fieldValues) temp.SemisType = fieldValues.SemisType ? '' : 'Ce champ est requis.'
        if ('quantity' in fieldValues) temp.quantity = fieldValues.quantity ? '' : 'Ce champ est requis.'
        if ('unity' in fieldValues) temp.unity = fieldValues.unity ? '' : 'Ce champ est requis.'
        if ('pot_number' in fieldValues) temp.pot_number = fieldValues.pot_number ? '' : 'Ce champ est requis.'
        if ('pot_size' in fieldValues) temp.pot_size = fieldValues.pot_size ? '' : 'Ce champ est requis.'
        // if ('first_germination_date' in fieldValues)
        //     temp.first_germination_date = fieldValues.first_germination_date ? '' : 'Ce champ est requis.'
        // if ('specie_id' in fieldValues) temp.specie_id = fieldValues.specie_id ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '')
        }
    }

    const { values, errors, setErrors, handleInputChange, resetForm, setValues } = useForm(
        initialValues,
        true,
        validate
    )

    useState(() => {
        setValues(initialValues)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const dataToPost = {
            ...values,
            SemisDate: values?.SemisDate || new Date(),
            nursery_id: nursery_id,
            specie_id: specieId,
            user: [user.firstname, user.lastname].join(" "),
            // first_germination_date: values?.first_germination_date || new Date()
        }
        setSupplementData(dataToPost.nursery_id)
            if (validate()) {
            console.log(dataToPost)
            const result = await addOrEdit(
                dataToPost,
                resetForm,
                service,
                setNeedLoad,
                setUserHaveToPost,
                setSelectedSemisOnAdd(dataToPost.specie_id)
            )
            if (result) {
                setUserHavingToAdd(false)
                setUserHaveToPost(false)
            }
        }
    }

    const handleEditGermination = () => {
        setDisableGerminationDate(false)
        setFirst_germination_date(new Date().toISOString())
    }

    const handleUpdateFirstGerminationDate = () => {
        const dataToPost = {
            id: values.id,
            first_germination_date: values.first_germination_date || new Date().toISOString()
        }
        update(dataToPost, service, setNeedLoad)
        setDisableGerminationDate(true)
    }

    useEffect(() => {
        if (dataSemisSelected) {
            setSemisSelected(dataSemisSelected.id)
            resetForm()
            setValues({
                ...values,
                ...dataSemisSelected
            })
        }
    }, [dataSemisSelected, setValues, values, resetForm, setSemisSelected])

    useEffect(() => {
        if (userHavingToAdd && !dataSemisSelected) {
            setInputDisabled(false)
        } else {
            setInputDisabled(true)
        }
    }, [userHavingToAdd, resetForm, dataSemisSelected])

    // change dataSemisSelected value to null if values changes
    useEffect(() => {
        setDataSemisSelected(null)
    }, [values])

    return (
        <>
            <Paper sx={styles.list_semi_container}>
                <CustomDropdown
                    data={dataSemis}
                    setUserHavingToAdd={setUserHavingToAdd}
                    setDataSemisSelected={setDataSemisSelected}
                    resetForm={resetForm}
                />
            </Paper>
            <Form onSubmit={handleSubmit}>
                <Paper sx={{ ...styles.paper, borderTop: '1px solid #E5E5E5' }}>
                    <Paper>
                        <Typography sx={styles.form_title}>Semis</Typography>
                    </Paper>
                    <Grid container>
                        <Grid item xs={4}>
                            <Paper sx={styles.form_input_container}>
                                <Controls.CustomSelect
                                    name="SemisType"
                                    label="Type de semis"
                                    options={dataSemisType}
                                    labelKey={'label'}
                                    valueKey={'label'}
                                    value={values.SemisType}
                                    error={errors.SemisType}
                                    onChange={handleInputChange}
                                    isDisabled={inputDisabled}
                                    isRequired={true}
                                />
                                <Controls.DatePicker
                                    label="Date de semis"
                                    name="SemisDate"
                                    isDisabled={inputDisabled}
                                    value={values?.SemisDate}
                                    onChange={handleInputChange}
                                    error={errors.SemisDate}
                                    isRequired={true}
                                />
                                {/* <Controls.CustomSelect
                                    name="specie_id"
                                    label="Espèce"
                                    options={dataSemisSpecies}
                                    labelKey={'name'}
                                    valueKey={'id'}
                                    value={values.specie_id}
                                    error={errors.specie_id}
                                    onChange={handleInputChange}
                                    isDisabled={inputDisabled}
                                /> */}
                                {userHavingToAdd && (
                                    <>
                                        <div style={{ marginBottom: '20px' }}>
                                            <Controls.Button
                                                type="submit"
                                                text="Enregistrer"
                                                haveSpin={true}
                                                disabled={userHaveToPost}
                                            />
                                        </div>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={styles.form_input_container}>
                                <Controls.Input
                                    label="Quantité"
                                    name="quantity"
                                    isDisabled={inputDisabled}
                                    value={values.quantity}
                                    onChange={handleInputChange}
                                    error={errors.quantity}
                                    isRequired={true}
                                />
                                <Controls.Input
                                    label="Nombre de pot"
                                    name="pot_number"
                                    isDisabled={inputDisabled}
                                    value={values.pot_number}
                                    onChange={handleInputChange}
                                    error={errors.pot_number}
                                    isRequired={true}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={styles.form_input_container}>
                                <Controls.CustomSelect
                                    name="unity"
                                    label="Unité"
                                    options={dataSemisUnity}
                                    labelKey={'label'}
                                    valueKey={'label'}
                                    value={values.unity}
                                    error={errors.unity}
                                    onChange={handleInputChange}
                                    isDisabled={inputDisabled}
                                    isRequired={true}
                                />
                                <Controls.CustomSelect
                                    label="Taille de pot"
                                    name="pot_size"
                                    options={dataPotSizes}
                                    labelKey={'label'}
                                    valueKey={'label'}
                                    value={values.pot_size}
                                    error={errors.pot_size}
                                    onChange={handleInputChange}
                                    isDisabled={inputDisabled}
                                    isRequired={true}
                                />
                            </Paper>
                        </Grid>
                        <Paper>
                            <Typography
                                sx={styles.form_title}
                                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                            >
                                Germination
                                {!values.first_germination_date && (
                                    <div>
                                        <Controls.Button text="Editer" size="small" onClick={handleEditGermination} />
                                    </div>
                                )}
                            </Typography>
                            <Paper sx={styles.form_input_container}>
                                <Controls.DatePicker
                                    label="Date de première germination"
                                    name="first_germination_date"
                                    isDisabled={disableGerminationDate}
                                    value={userHavingToAdd ? null : values.first_germination_date}
                                    onChange={handleInputChange}
                                />
                            </Paper>
                            {!disableGerminationDate && (
                                <div>
                                    <Controls.Button
                                        text="Mettre à jour"
                                        size="small"
                                        onClick={handleUpdateFirstGerminationDate}
                                    />
                                </div>
                            )}
                        </Paper>
                        {/*    <Grid item xs={3}>
                            <Paper sx={styles.form_input_container}>
                                <Controls.CustomSelect
                                    name="unity"
                                    label="Unité"
                                    options={dataSemisUnity}
                                    labelKey={'label'}
                                    valueKey={'label'}
                                    value={values.unity}
                                    error={errors.unity}
                                    onChange={handleInputChange}
                                    isDisabled={inputDisabled}
                                />
                            </Paper>
                        </Grid> */}
                    </Grid>
                </Paper>
            </Form>
        </>
    )
}

export default SemiFormView
