import { Container, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls.js'
import DatePickerInput from '../../../../../../components/Entry/DatePicker'

import React, { useEffect } from 'react'
import { Form } from '../../../../../../applications/hooks/UseForm'
import { makeStyles } from '@mui/styles'

const { Input: TextField } = Controls

const useStyles = makeStyles((theme) => ({
    columnForm: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: 'none',
        boxShadow: 'none',
        '& .MuiTextField-root': {
            marginBottom: '20px',
            marginLeft: '0px'
        }
    }
}))

function ReforestationInfo(props) {
    const { records } = props
    const classes = useStyles()

    const handleChange = () => {}

    useEffect(() => {
        console.log(records)
    }, [records])

    return (
        <Form>
            <Grid container>
                <Grid item xs={6}>
                    <Container className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="plot"
                            label="Parcelle"
                            value={records?.plot_code || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Fokontany"
                            name="fokontany"
                            value={records?.fkt || ''}
                        />

                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Nom paysan"
                            name="peasant"
                            value={records?.peasant_firstname + ' ' + records?.peasant_lastname || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="number_tree_planted"
                            label="Nombre d'arbres plantés"
                            value={records?.nb_tree_planted || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Surface plantée (m2)"
                            name="surface"
                            value={records?.surface || ''}
                        />
                    </Container>
                </Grid>
                <Grid item xs={6}>
                    {/* <TextField name="peasant" label="Commune" value={records?.peasant || ''} /> */}
                    <Container className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="commune"
                            label="Commune"
                            value={records?.commune || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="village"
                            label="Village"
                            value={records?.village || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Commande"
                            name="order"
                            value={records?.customer || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Client"
                            name="customer"
                            value={records?.customer || ''}
                        />
                        <Controls.DatePicker
                            name="planting_date"
                            label="Date de plantation"
                            isDisabled={true}
                            value={records?.planting_date || new Date()}
                        />
                    </Container>
                </Grid>
            </Grid>
        </Form>
    )
}

export default ReforestationInfo
