import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class GroupsService extends Service {
    static messages = {
        update_success: 'Modification du formulaire avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création formulaire avec succès.',
        create_error: 'Erreur de création.'
    }

    static load() {
        this.setUrl('/api/groups')
        return this
    }

    static async create(data) {
        return await Http.post(this.url, data)
    }

    static async createMany(data) {
        return await Http.post(['api/groups/createMany'].join('/'), data)
    }
}

export default GroupsService
