import { Avatar, Box, Container, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormContent from '../../../../../components/PageView/Form/FormContent'
import { makeStyles } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Loader from '../../../../../components/Entry/Loading'
import ReforestationInfo from './form/ReforestationInfo'
import SpeciesCard from './card/SpeciesCard'
import MonitoringTable from './table/MonitoringTable'
import MonitoringForm from './form/MonitoringForm'
import PlantationsMonitoring from '../../../services/PlantationsMonitoring.js'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../../components/Entry/Map.js'
import QrViewer from '../../../../../components/Entry/QrViewer.js'
import FollowUpVisit from './table/FollowUpVisit.jsx'
import PlantationsVisit from '../../../services/PlantationVisit.js'

const greenColor = '#14b67d'

const useStyles = {
    paper: {
        boxShadow: 'none'
    },
    cardContainer: {
        backgroundColor: '#f8f8f8',
        border: 'none',
        boxShadow: 'none'
    },

    paperTextField: {
        TextField: {
            backgroundColor: '#ffffff'
        }
    },
    titleGps: {
        padding: `30px 0 30px 0 `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#677788',
        fontSize: '0.8rem'
    },
    dateTime: {
        padding: `30px`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontSize: '0.8rem'
    },
    exportButton: {
        backgroundColor: 'white',
        borderColor: greenColor,
        color: greenColor,
        '&:hover': {
            backgroundColor: greenColor,
            color: 'white'
        }
    },
    monitoringTable: {
        padding: `0 30px 30px 30px`,
        boxShadow: 'none'
    },
    titleMonitoring: {
        padding: `30px`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontSize: '0.8rem'
    },
    title: {
        padding: `30px`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    coordinateContainer: {
        display: 'grid',
        '& .MuiButtonBase-root': {
            width: '150px',
            marginTop: '35px',
            marginLeft: 'auto'
        }
    }
}

function PlantationDetailsContent(props) {
    const { service, records, needLoad } = props
    const classes = useStyles
    const [loading, setLoading] = useState(false)
    const [dataTableMonitoring, setDataTableMonitoring] = useState([])
    const [dataTableFollowUp, setDataTableFollowUp] = useState([])
    const [plotGrid, setPlotGrid] = useState(null)
    const visitService = PlantationsVisit.load()

    useEffect(() => {
        const fetchData = async () => {
            if (needLoad && records?.id) {
                setLoading(true)
                const data = await PlantationsMonitoring.getMonitoringByPlantationId(records?.id)
                const dataVisit = await visitService.getByPlantationId(records?.id)
                if (data) {
                    setDataTableMonitoring(data?.data)
                }
                if (dataVisit) {
                    setDataTableFollowUp(dataVisit?.data)
                }
                setLoading(false)
            }
        }

        fetchData()
    }, [needLoad, records])

    useEffect(() => {
        console.log('records : >>>>>>>>', records)
        if (records && records?.grid) {
            console.log('records : ', records)
            setPlotGrid([
                {
                    id: 1,
                    coordinates: records?.grid?.coordinates
                }
            ])
        }
    }, [records])

    useEffect(() => {
        if (plotGrid) {
            console.log('plotGrid : ', plotGrid)
        }
    }, [plotGrid])

    return (
        <>
            <Loader isLoading={loading} />
            <CssBaseline />
            <Grid container>
                {/* Première colonne (2/3 de la page) */}
                <Grid item xs={8} className={classes.paper} style={{ borderRight: '1px solid #ccc' }}>
                    <div className={classes.paper}>
                        <FormContent
                            className={classes.paper}
                            title={`Information sur le reboisement`}
                            data={records}
                            service={service}
                            Form={ReforestationInfo}
                        />
                    </div>
                </Grid>
                {/* Deuxième colonne (1/3 de la page) */}
                <Grid item xs={4} style={{ paddingTop: '30px' }}>
                    <Container className={classes.coordinateContainer}>
                        <Typography className={`${classes.titleGps}`} component="div">
                            Coordonnée GPS
                        </Typography>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#f8f8f8'
                                }
                            }}
                            size="small"
                            fullWidth
                            id="gps-coordinate"
                            variant="outlined"
                            value={
                                `latitude : ${JSON.stringify(
                                    records?.grid?.coordinates[0].lat
                                )} - longitude : ${JSON.stringify(records?.grid?.coordinates[0].lng)} ` || ''
                            }
                            disabled={true}
                        />
                        <LoadScript>{plotGrid && <Map data={plotGrid} zoom={10} />}</LoadScript>
                        {!plotGrid && <Typography style={{ textAlign: 'center' }}>(Aucun quadrillage.)</Typography>}
                        <Box sx={{ paddingTop: '40px' }}>
                            <Container>
                                <QrViewer value={records?.code_qr} size={'60%'} />
                            </Container>
                        </Box>
                    </Container>
                </Grid>
            </Grid>

            <SpeciesCard records={records} needLoad={needLoad} />

            <div style={classes.monitoringTable}>
                <FollowUpVisit data={dataTableFollowUp} records={records} />
            </div>

            <div style={classes.monitoringTable}>
                <MonitoringTable data={dataTableMonitoring} records={records} />
            </div>

            {dataTableMonitoring.length > 0 && (
                <div style={classes.cardContainer}>
                    <Typography style={classes.title} component="div">
                        Monitoring
                    </Typography>
                    {dataTableMonitoring
                        .filter((monitoring) => monitoring.regarnissage === 0)
                        .map((monitoring, index) => {
                            return <MonitoringForm key={index} records={monitoring} />
                        })}
                </div>
            )}
        </>
    )
}

export default PlantationDetailsContent
