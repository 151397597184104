import { makeStyles } from '@mui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Container, CssBaseline, Grid, Paper } from '@mui/material'
import NurserySiteCard from './card/NurserySiteCard'
import SemiFormView from './form/SemiFormView'
import YoungPlantDevelopment from './form/YoungPlantDevelopment'
import Sortie from './form/Sortie'
import NurseryTable from './table/NurseryTable'
import { Context } from '../../../utils/hooks/NurseryDataContext'
import { isEmptyObject } from '../../../../../../../applications/UtilFunctions'
import QrViewer from '../../../../../../../components/Entry/QrViewer'

const useStyles = {
    root: {
        flexGrow: 1
    },
    paper: {
        color: 'transparent',
        boxShadow: 'none'
    }
}

const styles = {
    second_section_container: {
        padding: 1,
        backgroundColor: 'none',
        boxShadow: 'none',
        borderLeft: '1px solid #E5E5E5'
    },
    paper: {
        padding: '20px 0px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    list_semi_container: {
        padding: '20px 0px 20px 0px',
        backgroundColor: '#f8fcff',
        boxShadow: 'none'
    },
    form_title: {
        padding: `10px 10px 20px 10px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    form_input_container: {
        padding: '0px 10px 0px 10px'
    }
}

function NurseryDetailsContent(props) {
    const { setNeedLoad, setSupplementData, otherFunction } = props
    const classes = styles
    const data = useContext(Context)
    const [siteCardData, setSiteCardData] = useState({})
    const [semisData, setSemisData] = useState([])
    const [semisUnityData, setSemisUnityData] = useState([])
    const [dataSemisType, setDataSemisType] = useState([])
    const [dataPotSizes, setDataPotSizes] = useState([])
    const [dataSemisSpecies, setDataSemisSpecies] = useState([])
    const [dataSemisSpeciesCard, setDataSemisSpeciesCard] = useState([])
    const [semisTrackingData, setSemisTrackingData] = useState({})
    const [idSemisSelected, setIdSemisSelected] = useState(null)
    const [semisSelected, setSemisSelected] = useState({})
    const [semisDataGetOut, setSemisDataGetOut] = useState({})
    const [semisDataGetOutSelected, setSemisDataGetOutSelected] = useState({})
    const [plotData, setPlotData] = useState([])
    const [selectedSpecie, setSelectedSpecie] = useState({})
    const [selectedNurserySpecie, setSelectedNurserySpecie] = useState({})
    const [userHavingToAdd, setUserHavingToAdd] = useState(null)
    const [recorgedPotData, setRecorgedPotData] = useState([])

    const [semisTrackingSelected, setSemisTrackingSelected] = useState([])

    const { setSelectedSemisOnAdd } = otherFunction

    useEffect(() => {
        if (data?.selectedSpecie) {
            console.log('le selected specie : ', data?.selectedSpecie)
            setSelectedSpecie(data?.selectedSpecie)
        }
    }, [data?.selectedSpecie])

    useEffect(() => {
        if (!isEmptyObject(data)) {
            console.log(data);
            setSiteCardData(data?.dataNursery)
            setSemisData(data?.dataSemis.filter((semis) => semis.specie.id === selectedSpecie))
            setSemisUnityData(JSON.parse(data?.dataSemisUnity.database))
            setDataSemisType(JSON.parse(data?.dataSemisType.database))
            setDataPotSizes(JSON.parse(data?.dataPotSizes.database))
            setDataSemisSpecies(data?.dataSemisSpecies)
            setDataSemisSpeciesCard(data?.dataSpeciesNursery)
            setSemisTrackingData(data?.semisTrackingData)
            setSemisDataGetOut(data?.semisDataGetOut)
            setPlotData(data?.plotData)
            setRecorgedPotData(data?.recorgedPotData)
        }
    }, [data, setNeedLoad, selectedSpecie])

    useEffect(() => {
        if (idSemisSelected) {
            console.log(idSemisSelected, 'idSemisSelected')
            setSemisTrackingSelected(semisTrackingData[`${idSemisSelected}`])
            const selectedSemis = semisData.find((semis) => semis.id === idSemisSelected)
            const selectedGetOut = semisDataGetOut[`${data?.selectedSpecie}`]
            setSemisSelected(selectedSemis)
            // setSemisDataGetOutSelected(selectedGetOut)
        }
    }, [idSemisSelected, semisTrackingData, semisData, semisDataGetOut, data])

    useEffect(() => {
        if (selectedSpecie) {
            setUserHavingToAdd(false)
            const selectedGetOut = semisDataGetOut[`${selectedSpecie}`]
            setSemisDataGetOutSelected(selectedGetOut)
            const nurserySpecie = data?.dataNursery?.AgroNurserySpecie.find(
                (specieNursery) => specieNursery.specie_id === selectedSpecie
            )
            console.log('nurserySpecie : ', nurserySpecie)
            setSelectedNurserySpecie(nurserySpecie)
        }
    }, [selectedSpecie, semisDataGetOut, data])

    return (
        <>
            <Grid container>
                {/* Première colonne (2/3 de la page) */}
                <Grid item xs={4} className={classes.paper}>
                    <div style={classes.paper}>
                        <NurserySiteCard
                            records={siteCardData}
                            dataSpeciesNursery={dataSemisSpeciesCard}
                            recorgedPotData={recorgedPotData}
                            selectedSpecie={selectedSpecie}
                            setSelectedSpecie={setSelectedSpecie}
                            setSupplementData={setSupplementData}
                        />
                    </div>
                    <Box sx={{ paddingTop: '40px' }}>
                        <Container>
                            <QrViewer value={siteCardData?.code_qr} size={'60%'} />
                        </Container>
                    </Box>
                </Grid>
                {/* Deuxième colonne (1/3 de la page) */}
                <Grid item xs={8}>
                    <Paper sx={styles.second_section_container}>
                        <SemiFormView
                            styles={styles}
                            dataSemis={semisData}
                            dataSemisUnity={semisUnityData}
                            dataSemisType={dataSemisType}
                            dataPotSizes={dataPotSizes}
                            dataSemisSpecies={dataSemisSpecies}
                            nursery_id={siteCardData.id}
                            setNeedLoad={setNeedLoad}
                            specieId={selectedNurserySpecie?.specie_id}
                            setSemisSelected={setIdSemisSelected}
                            setSupplementData={setSupplementData}
                            setSelectedSemisOnAdd={setSelectedSemisOnAdd}
                            userHavingToAdd={userHavingToAdd}
                            setUserHavingToAdd={setUserHavingToAdd}
                        />
                        <YoungPlantDevelopment
                            styles={styles}
                            selectedSpecie={selectedSpecie}
                            semisTrackingData={semisTrackingData}
                            semisTrackingSelected={semisTrackingSelected}
                            setNeedLoad={setNeedLoad}
                            semisSelected={semisSelected}
                        />
                        <Sortie
                            selectedSpecie={selectedSpecie}
                            styles={styles}
                            selectedNurserySpecie={selectedNurserySpecie}
                            semisDataGetOutSelected={semisDataGetOutSelected}
                            plotData={plotData}
                            setNeedLoad={setNeedLoad}
                        />
                        <NurseryTable semisDataGetOutSelected={semisDataGetOutSelected} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default NurseryDetailsContent
