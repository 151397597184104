import { Container } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import Loader from '../../../../../components/Entry/Loading'

export default function CustomListSpecies(props) {
    const { filteredData, onItemClick, onValuesChange } = props
    const [speciesData, setSpeciesData] = useState(
        filteredData.map((item) => ({ ...item, checked: false, number: '' }))
    )
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (speciesData.length === 0) {
            setSpeciesData(filteredData.map((item) => ({ ...item, checked: false, number: '' })))
            setLoading(false)
        }
    }, [speciesData, filteredData])

    const handleCheckboxChange = (id) => {
        const updatedSpeciesData = speciesData.map((item) =>
            item.id === id ? { ...item, checked: !item.checked } : item
        )
        setSpeciesData(updatedSpeciesData)
        onValuesChange(getSelectedValues(updatedSpeciesData))
    }

    const handleNumberInputChange = (id, number) => {
        const updatedSpeciesData = speciesData.map((item) => (item.id === id ? { ...item, number: number } : item))
        setSpeciesData(updatedSpeciesData)

        onValuesChange(getSelectedValues(updatedSpeciesData))
    }

    const getSelectedValues = (data) => {
        return data.filter((item) => item.checked).map(({ name, number }) => ({ name, number }))
    }

    return (
        <>
            <Loader loading={loading} />
            <Container
                sx={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    overflowY: 'scroll',
                    maxHeight: '250px',
                    overflowX: 'hidden'
                }}
            >
                <List>
                    {speciesData.map((item) => (
                        <ListItem key={item.id} sx={{ display: '-webkit-inline-box', width: 'auto' }}>
                            <Checkbox
                                edge="start"
                                checked={item.checked || false}
                                onChange={() => {
                                    handleCheckboxChange(item.id)
                                    onItemClick(item.id, item.name)
                                }}
                            />
                            <ListItemText primary={item.name} sx={{ cursor: 'pointer' }} />
                            <TextField
                                label="nombre"
                                value={item.number}
                                onChange={(e) => handleNumberInputChange(item.id, e.target.value)}
                                size="small"
                                sx={{ flexGrow: 1, minWidth: '120px' }}
                                type="number"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </>
    )
}
