import React, { useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'

function Photo({ onChange, value }) {

    return (
        <div>
            <Controls.ImageInput
                value={value.photos}
                name="photos"
                onChange={onChange}
                label="Image"
            />
        </div>
    )
}

export default Photo
