import React, { useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import { Typography } from '@mui/material'
import { FaTrashAlt } from 'react-icons/fa'
import GroupsService from '../../../../provinces/services/Groups'
import Notification from '../../../../../layouts/Notification'

function AddGroup(props) {
    const { setUserHaveToAddGroup, village, category, setOpenPopup, setGroupSaved } = props
    const [values, setValues] = useState([])
    const [value, setValue] = useState('')
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const handleChange = (e) => {
        e.preventDefault()
        setValue(e.target.value)
    }

    const handleSave = () => {
        if (values.length > 0) {
            setUserHaveToPost(true)
            const service = GroupsService.load()
            service
                .createMany({ category: category, parent: village.id, groups: values.map((item) => item.name) })
                .then((response) => {
                    if (response?.data) {
                        setUserHaveToAddGroup(false)
                        setOpenPopup(false)
                        setGroupSaved(true)
                        setUserHaveToPost(false)
                    }
                })
        } else {
            new Notification('Aucun groupe à enregistrer', 0)
        }
    }

    const handleCancel = () => {
        setUserHaveToAddGroup(false)
    }
    return (
        <>
            <div style={{ height: '400px' }}>
                <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <b>{village?.name}</b>
                </h2>
                <Controls.Input label="Nom du groupe" name="group" value={value} onChange={handleChange} />
                <Controls.Button
                    text="Ajouter"
                    onClick={() => {
                        if (value !== '') {
                            setValues([...values, { id: values.length + 1, name: value }])
                            setValue('')
                        }
                    }}
                />
                <Typography
                    variant="caption"
                    sx={{
                        margin: '10px 0 10px 0'
                    }}
                    component="div"
                    color={'text.secondary'}
                >
                    <i>Groupes à ajouter :</i>
                </Typography>
                <div
                    style={{
                        margin: '10px 0 10px 0',
                        height: '200px',
                        overflowY: 'scroll'
                    }}
                >
                    {values?.map((value) => (
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <p key={value.id}>- {value.name}</p>
                            <FaTrashAlt
                                style={{ cursor: 'pointer', color: 'red', marginTop: '5px' }}
                                onClick={() => setValues(values.filter((v) => v.id !== value.id))}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}
            >
                <Controls.Button text="Enregistrer" onClick={handleSave} haveSpin={true} disabled={userHaveToPost} />
                <Controls.Button text="Annuler" onClick={handleCancel} haveSpin={true} disabled={userHaveToPost} />
            </div>
        </>
    )
}

export default AddGroup
