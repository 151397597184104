import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'

import DataSetsService from '../../datasets/services/DataSets'
import SelectDataSet from '../../../components/Entry/SelectDataSet'
import OrderTrackingService from '../services/OrderTracking'
import CustomSelect from '../../../components/Entry/CustomSelect'
import CustomerOrderService from '../../customer_order/services/CustomerOrder'
import ProvinceService from '../../provinces/services/Provinces'
import { TiDelete } from 'react-icons/ti'
import NurseryService from '../../nursery/services/Nursery'
import PlotService from '../../plot/services/Plots'
import GetOutSemisService from '../../nursery/services/semis/GetOut'
import OrderItemsService from '../services/OrderItems'

const { Input: TextField } = Controls

const initialFValues = {
    customers: '',
    type: '',
    site_id: '',
    tree_ordered: '',
    location: '',
    plot_id: '',
    getout_date: '',
    commands: []
}

const types = [{ label: 'Agroforesterie' }, { label: 'Mangroves' }]

export const OrderTrackingForm = (props) => {
    const { records } = props
    const location = useLocation()

    const path = location.pathname.split('/').pop()
    const showBtnCommands = location.pathname.includes('commercial')

    console.log('showBtnCommands', showBtnCommands)

    const navigate = useNavigate()
    const dataset = DataSetsService.load()
    const customer_api = CustomerOrderService.load()
    const site_api = ProvinceService.load()
    const plot_api = PlotService.load()
    const order_item_api = OrderItemsService.load()

    const [dataState, setDataState] = useState([])
    const [customer, setCustomer] = useState([])
    const [mangrosite, setMangroSite] = useState([])
    const [agrosite, setAgroSite] = useState([])
    const [sites, setSites] = useState([])
    const [plots, setPlots] = useState([])
    const [nursery, setNursery] = useState([])
    const [orders, setOrders] = useState([])
    const [siteNames, setSiteNames] = useState({})

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                customers: records?.customers?.id || '',
                type: records?.type || '',
                site_id: records?.site_id || '',
                plot_id: records?.plot_id || '',
                tree_ordered: records?.tree_ordered || '',
                location: records?.location || '',
                getout_date: records?.getout_date || '',

                commands: records?.commands || initialFValues.commands
            }))
        }
    }, [records])

    useEffect(() => {
        customer_api.getCustomerOrder().then((res) => {
            setCustomer(res)
        })
        site_api.getVillages().then((res) => {
            setMangroSite(res?.data)
        })
        site_api.getVillages().then((res) => {
            setAgroSite(res?.data)
        })
        dataset
            .getDataCode('statut_commande')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    useEffect(() => {
        const siteNamesObj = {}
        sites.forEach((site) => {
            siteNamesObj[site.id] = site.name
        })
        setSiteNames(siteNamesObj)
    }, [sites])

    useEffect(() => {
        if (records?.site_id) {
            NurseryService.getAllNurseryBySite(records.site_id)
                .then((res) => {
                    setNursery(res)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des pépinières :', error)
                })
        }
        if (records?.id) {
            order_item_api
                .getOrderItemByOrderId(records.id)
                .then((res) => {
                    setOrders(res)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des pépinières :', error)
                })
        }
    }, [records])

    console.log('orders', orders)

    useEffect(() => {
        plot_api
            .getAllPlots()
            .then((users) => {
                if (users) {
                    setPlots(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        if (orders) {
            const updatedCommands = orders.map((order) => ({
                nursery_id: order.nursery_id,
                nursery_semi: [
                    {
                        id: order.nursery_semis_id,
                        quantity: order.quantity
                    }
                ],
                isDisabled: true
            }))
            setValues((prevValues) => ({
                ...prevValues,
                commands: updatedCommands
            }))
        }
    }, [orders])

    console.log('nursery', nursery)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('site_id' in fieldValues) temp.site_id = fieldValues.site_id ? '' : 'Ce champ est requis.'
        if ('type' in fieldValues) temp.type = fieldValues.type ? '' : 'Ce champ est requis.'
        if ('tree_ordered' in fieldValues) temp.tree_ordered = fieldValues.tree_ordered ? '' : 'Ce champ est requis.'
        if ('customers' in fieldValues) temp.customers = fieldValues.customers.length != 0 ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }
    const validateOrder = (fieldValues = values) => {
        let temp = { ...errors }
        if ('getout_date' in fieldValues) temp.getout_date = fieldValues.getout_date ? '' : 'Ce champ est requis.'
        if ('plot_id' in fieldValues) temp.plot_id = fieldValues.plot_id.length != 0 ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            const dataToSend = { ...values }
            delete dataToSend.customers
            delete dataToSend.commands
            delete dataToSend.plot_id
            delete dataToSend.getout_date

            dataToSend.state = dataState[0].label
            dataToSend.location = siteNames[dataToSend.site_id]
            dataToSend.customer_id = values.customers

            const commandsData = values.commands.filter((command) => !command.isDisabled)
            let order = null
            let message = ''

            try {
                if (records?.id) {
                    if (validateOrder()) {
                        for (const commandData of commandsData) {
                            for (const semi of commandData.nursery_semi) {
                                const nurserySemiData = {
                                    nursery_semis_id: semi.id,
                                    quantity: semi.quantity,
                                    plot_id: parseInt(values.plot_id),
                                    getout_date: values.getout_date
                                }

                                const response = await GetOutSemisService.addGetOut(nurserySemiData)
                                if (response) {
                                    new Notification('Sortie effectuée avec succès', 1)
                                }
                            }
                        }
                        for (const commandData of commandsData) {
                            for (const semi of commandData.nursery_semi) {
                                const order_item = {
                                    nursery_semis_id: semi.id,
                                    quantity: semi.quantity,
                                    order_id: parseInt(records.id),
                                    nursery_id: parseInt(values.nursery_id)
                                }

                                const response = await OrderItemsService.createOrderItem(order_item)
                                if (response) {
                                    new Notification('Commande effectuée avec succès', 1)
                                    navigate(-1)
                                }
                            }
                        }
                    }
                } else {
                    order = await OrderTrackingService.addOrderTracking(dataToSend)
                    message = OrderTrackingService.messages.create_success

                    if (order) {
                        new Notification(message, 1)
                        navigate(-1)
                    } else {
                        new Notification(message, 0)
                    }
                }
            } catch (error) {
                console.error("Une erreur s'est produite lors de l'envoi des données :", error)
            }
        }
    }

    const handleChange = (e, index) => {
        handleInputChange(e)
        const { name, value } = e.target
        const updatedCommands = [...values.commands]
        updatedCommands[index] = {
            ...updatedCommands[index],
            [name]: value
        }
        setValues((prevFormData) => ({
            ...prevFormData,
            commands: updatedCommands
        }))

        if (e.target.name === 'type') {
            if (e.target.value === 'Agroforesterie') {
                setSites(agrosite)
            } else {
                setSites(mangrosite)
            }
        }
    }

    const handleChangeNursery = (e, index) => {
        handleInputChange(e)
        const { name, value } = e.target

        const updatedCommands = [...values.commands]
        updatedCommands[index] = {
            ...updatedCommands[index],
            [name]: value,
            nursery_semi: nursery
                .find((n) => n.id === value)
                ?.AgroNurserySemis?.map((item) => ({ id: item.id, quantity: null }))
        }
        setValues((prevFormData) => ({
            ...prevFormData,
            commands: updatedCommands
        }))

        if (e.target.name === 'type') {
            if (e.target.value === 'Agroforesterie') {
                setSites(agrosite)
            } else {
                setSites(mangrosite)
            }
        }
    }

    const handleAddCommand = () => {
        setValues((prevFormData) => ({
            ...prevFormData,
            commands: [
                ...prevFormData.commands,
                {
                    nursery_id: '',
                    nursery_semi: [
                        {
                            id: '',
                            quantity: null
                        }
                    ]
                }
            ]
        }))
    }

    const handleDeleteCommand = (index) => {
        const updatedCommands = [...values.commands]
        updatedCommands.splice(index, 1)
        setValues((prevFormData) => ({
            ...prevFormData,
            commands: updatedCommands
        }))
    }

    const handleQuantity = (e, item, index) => {
        console.log('aaaaaaa', index, item, e.target.value)
        const data = {
            id: item.id,
            quantity: +e.target.value
        }
        setValues((prevFormData) => ({
            ...prevFormData,
            commands: prevFormData.commands.map((command, i) => {
                if (i !== index) {
                    return command
                } else {
                    if (command.nursery_semi?.length) {
                        return {
                            ...command,
                            nursery_semi: command.nursery_semi.map((semi) => {
                                if (semi.id !== item.id) {
                                    return semi
                                } else {
                                    return {
                                        ...data
                                    }
                                }
                            })
                        }
                    } else {
                        return {
                            ...command,
                            nursery_semi: [...command.nursery_semi, data]
                        }
                    }
                }
            })
        }))
    }

    const totalOrderedTrees = values.commands.reduce((acc, command) => {
        return (
            acc +
            command.nursery_semi.reduce((acc, semi) => {
                return acc + semi.quantity
            }, 0)
        )
    }, 0)
    const totalOrderedTreesSave = orders?.reduce((acc, command) => {
        return acc + command.quantity
    }, 0)

    const tree_needed = totalOrderedTrees >= records?.tree_ordered
    const tree_needed_save = totalOrderedTreesSave >= records?.tree_ordered

    console.log('ORDERS', orders)

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div>
                    <div className="flex p-4">
                        <div className="w-full p-2">
                            <>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <CustomSelect
                                            label="Client"
                                            name="customers"
                                            value={values?.customers || ''}
                                            options={customer}
                                            onChange={handleChange}
                                            error={errors.customers}
                                            labelKey="firstname"
                                            valueKey="id"
                                            isDisabled={path !== 'new'}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <SelectDataSet
                                            label="Type"
                                            value={values.type || ''}
                                            onChange={handleChange}
                                            name="type"
                                            options={types}
                                            error={errors?.type}
                                            isDisabled={path !== 'new'}
                                        />
                                    </div>
                                    <div className="w-full">
                                        {path !== 'new' ? (
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                name="location"
                                                value={values?.location}
                                                size="small"
                                                label="Localisation demandée"
                                                disabled
                                            />
                                        ) : (
                                            <CustomSelect
                                                label="Localisation demandée"
                                                name="site_id"
                                                value={values?.site_id || ''}
                                                options={sites}
                                                onChange={handleChange}
                                                error={errors.site_id}
                                                labelKey="name"
                                                valueKey="id"
                                                isRequired={true}
                                            />
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="tree_ordered"
                                            value={values?.tree_ordered}
                                            size="small"
                                            label="Arbre commandés"
                                            onChange={handleChange}
                                            error={errors.tree_ordered}
                                            disabled={path !== 'new'}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>

                    {path !== 'new' && values.type === 'Agroforesterie' && !showBtnCommands && !tree_needed_save && (
                        <div className="ml-[20px] mb-[30px] flex gap-[40px]">
                            <div className="w-[40%]">
                                <CustomSelect
                                    label="Choisir Plantation"
                                    name="plot_id"
                                    value={values?.plot_id || ''}
                                    options={plots}
                                    onChange={handleChange}
                                    error={errors.plot_id}
                                    labelKey="code"
                                    valueKey="id"
                                    isRequired={true}
                                />
                            </div>
                            <div className="w-[40%]">
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="getout_date"
                                    value={values?.getout_date}
                                    size="small"
                                    label="Date de sortie"
                                    onChange={handleChange}
                                    error={errors.getout_date}
                                    type="date"
                                    isRequired={true}
                                />
                            </div>
                        </div>
                    )}

                    {path !== 'new' && values.type === 'Agroforesterie' && (
                        <div className=" border border-gray-300 rounded-lg m-4 p-2 ">
                            <div className="flex justify-between ml-[20px]  font-semibold italic">
                                <p>Pépinière</p>
                                <p>Espèces</p>
                                <p className="mr-[100px]"> quantité</p>
                            </div>

                            {/* {orders &&
                                orders?.map((item, key) => (
                                    <div className=" p-3 " key={key}>
                                        <div className="w-full p-2   ">
                                            <div className="flex gap-[40px] mt-[20px]">
                                                <div className="w-[36%]">
                                                    <CustomSelect
                                                        name="nursery_id"
                                                        options={nursery}
                                                        value={item?.nursery_id || ''}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        isDisabled
                                                    />
                                                </div>
                                                <div className="w-[80%]">
                                                    <div className="flex gap-[60px]">
                                                        <div className="w-[80%] mt-[16px]">
                                                            <div className="flex justify-between  p-2 min-h-[40px]">
                                                                <p>Ravintsara</p>
                                                                <p>{item?.nursery_semis?.plant_ready}</p>
                                                            </div>
                                                        </div>
                                                        <div className="w-full">{item?.quantity}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}

                            {values.commands.map((command, index) => (
                                <div key={index} className=" p-3 ">
                                    <div className="w-full p-2   ">
                                        <div className="flex gap-[40px] mb-[40px]">
                                            <div className="w-[40%]">
                                                <CustomSelect
                                                    value={command.nursery_id || ''}
                                                    onChange={(e) => handleChangeNursery(e, index)}
                                                    name="nursery_id"
                                                    options={nursery}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    isDisabled={command?.isDisabled}
                                                />
                                            </div>

                                            <div className="w-[80%]">
                                                {command?.nursery_semi?.map((item, key) => (
                                                    <div key={key} className="flex gap-[60px]">
                                                        <div className="w-full mt-[16px]">
                                                            <div className="flex justify-between  p-2 min-h-[40px]">
                                                                <p>
                                                                    {
                                                                        nursery
                                                                            ?.find(
                                                                                (n) =>
                                                                                    n.id ===
                                                                                    values.commands[index].nursery_id
                                                                            )
                                                                            ?.AgroNurserySemis?.find(
                                                                                (i) => i.id === item.id
                                                                            )?.specie?.name
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        nursery
                                                                            ?.find(
                                                                                (n) =>
                                                                                    n.id ===
                                                                                    values.commands[index].nursery_id
                                                                            )
                                                                            ?.AgroNurserySemis?.find(
                                                                                (i) => i.id === item.id
                                                                            )?.plant_ready
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="w-full">
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(e) => handleQuantity(e, item, index)}
                                                                type="number"
                                                                value={item?.quantity}
                                                                disabled={command.isDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className=" cursor-pointer italic">
                                                {command?.isDisabled !== true && (
                                                    <TiDelete
                                                        className="text-red-500 w-8 h-8"
                                                        onClick={() => handleDeleteCommand(index)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                {!tree_needed && (
                                    <div>
                                        {!showBtnCommands && (
                                            <p
                                                className="mb-4 cursor-pointer italic text-lime-500"
                                                onClick={handleAddCommand}
                                            >
                                                Ajouter
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!tree_needed_save && (
                                <div>
                                    {!showBtnCommands && (
                                        <div className="flex justify-end items-end m-5 p-5">
                                            <Controls.Button type="submit" text="Enregistrer" />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex justify-end items-end m-5 p-5">
                        {path === 'new' && <Controls.Button type="submit" text="Enregistrer" />}
                    </div>
                </div>
            </Form>
        </div>
    )
}
