import { Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../../../../../../../components/Entry/Controls'
import ToolbarButton from '../../../../../../../../components/DataTable/ToolbarButton'
import GetOutSemisService from '../../../../../../services/semis/GetOut'
import { useAuth } from '../../../../../../../../applications/hooks/UseAuth'
import AddSemisGetOut from './AddSemisGetOut'

function Sortie(props) {
    const { styles, selectedNurserySpecie, semisDataGetOutSelected, setNeedLoad, needLoad, plotData, selectedSpecie } =
        props
    const service = GetOutSemisService.load()
    const useDrawer = false
    const { haveAccessTo } = useAuth()
    const [ableToAddOuting, setAbleToAddOuting] = useState(false)

    useEffect(() => {
        if (selectedNurserySpecie) {
        }
    }, [selectedNurserySpecie])
    return (
        <>
            <Paper sx={styles.paper}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        padding: '10px 10px 20px 10px'
                    }}
                >
                    <Typography sx={{ ...styles.form_title, padding: '5px' }}>Sortie</Typography>
                    {ableToAddOuting && (
                        <ToolbarButton
                            moduleTitle="d'une sortie"
                            service={service}
                            drawer={useDrawer}
                            haveAccessTo={haveAccessTo}
                            setNeedLoad={setNeedLoad}
                            needLoad={needLoad}
                            Form={AddSemisGetOut}
                            size="small"
                            objectRelated={{ nurserySemisId: selectedNurserySpecie?.id, plotData: plotData }}
                        />
                    )}
                </Paper>
                <Grid container>
                    <Grid item xs={6}>
                        <Paper sx={styles.form_input_container}>
                            <Controls.Input
                                label="Nombre de plants produits"
                                name="type_semi"
                                value={`${selectedNurserySpecie?.plant_lived || '0'}`}
                                isDisabled={true}
                            />
                            <Controls.Input
                                label="Nombre de plants sorties"
                                name="type_semi"
                                value={`${semisDataGetOutSelected?.getOutNumber || '0'}`}
                                isDisabled={true}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={styles.form_input_container}>
                            <Controls.Input
                                label="Nombre de plants en stock"
                                name="type_semi"
                                value={`${selectedNurserySpecie?.plant_ready || '0'}`}
                                isDisabled={true}
                            />
                            <Controls.Input
                                label="Nombre de plants restant"
                                name="type_semi"
                                value={`${selectedNurserySpecie?.plant_ready || '0'}`}
                                isDisabled={true}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Sortie
