import React from 'react'
import Table from '../../../components/DataTable/Table'

function CustomerOrderList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad } = props
    const headCells = [
        { id: 'code', label: 'Référence client' },
        { id: 'firstname', label: 'Nom ' },
        { id: 'lastname', label: 'Prénom' },
        { id: 'state', label: 'Statut' },
        { id: 'phone', label: 'Téléphone' },
        { id: 'email', label: 'Email' },
        { id: 'place', label: 'Lieux' },

        {
            id: 'actions',
            type: 'actions',
            label: 'Actions',
            disableSorting: true,
            showEdit: true,
            showRemove: true,
            editPath: '/app/commercial/customer/edit'
        }
    ]

    return (
        <>
            <Table
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}

export default CustomerOrderList
