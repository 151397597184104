import React, { useContext, useEffect, useState } from 'react'
import { Grid, Container, Paper, Chip, Typography, Avatar } from '@mui/material'
import AddLandInformations from './form/AddLandInformations'
import AddSavedParameters from './card/AddSavedParameters'
import AddTopography from './form/AddTopography'
import Controls from '../../../../../components/Entry/Controls'
import { Context } from '../../utils/hooks/LandDataContext'
import { Form, useForm } from '../../../../../applications/hooks/UseForm'
import Loader from '../../../../../components/Entry/Loading'
import LandService from '../../../services/Land'
import AddTech from './popup/AddTech'
import AutoCompleteSelect from '../../../../../components/Entry/AutoCompleteSelect'
import ChipButton from '../../../../../components/Entry/ChipButton'
import { useAuth } from '../../../../../applications/hooks/UseAuth'
import addImage from '../../../../../assets/image/addPhoto.png'
import { addOrEdit, convertHaToM2, notification } from '../../../../../applications/UtilFunctions'
//import useNavigate
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../../layouts/Notification'
import VillageAutoCompleteSection from '../../../../provinces/components/VillageAutoCompleteSection'
import category from '../../../../../applications/constants/data'

const styles = {
    second_section_container: {
        padding: 1,
        backgroundColor: 'none',
        boxShadow: 'none',
        borderLeft: '1px solid #E5E5E5'
    },
    paper: {
        padding: '20px 0px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    list_semi_container: {
        padding: '20px 0px 20px 0px',
        backgroundColor: '#f8fcff',
        boxShadow: 'none'
    },
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    form_input_container: {
        padding: '0px 10px 0px 10px'
    },

    paperInput: {
        padding: '0px 20px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    addPhoto: {
        width: 70,
        height: 70,
        borderRadius: '5px',
        color: '#c5c4c4',
        backgroundColor: '#ececec',
        '&:hover': {
            backgroundColor: '#c4c4c4',
            cursor: 'pointer'
        }
    }
}

function AddLandDetailsContent(props) {
    const { setNeedLoad, action, user } = props
    // const landData = useContext(Context)
    // const { user } = useAuth()
    const navigate = useNavigate()

    /////////////////////////ADD NEW LAND////////////////////////
    const [dataAdd, setDataAdd] = useState(null)
    const [disabledField, setDisabledField] = useState(true)
    const [loading, setLoading] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [addedTech, setAddedTech] = useState(null)
    const [dataTechAdd, setDataTechAdd] = useState([])
    const [valueImage, setValueImage] = useState()
    const [landImages, setLandImages] = useState([])

    const [valuesToSave, setValuesToSave] = useState(null)

    const [deseaseOption, setDeseaseOption] = useState([
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ])
    const [landChangementOption, setLandChangementOption] = useState([
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ])

    /////data regions/////
    const [regionsList, setRegionsList] = useState(null)
    const [districtList, setDistrictList] = useState(null)
    const [communeList, setCommuneList] = useState(null)
    const [fktList, setFktList] = useState(null)
    const [villageList, setVillageList] = useState(null)
    const [siteList, setSiteList] = useState(null)

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [selectedCommune, setSelectedCommune] = useState(null)
    const [selectedFkt, setSelectedFkt] = useState(null)
    const [selectedVillage, setSelectedVillage] = useState(null)
    const [selectedSite, setSelectedSite] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [photos, setPhotos] = useState([])

    const initialValues = {}

    const [desease, setDesease] = useState(null)
    const [landChangement, setLandChangement] = useState(null)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('site' in fieldValues) temp.site = fieldValues.site ? '' : 'Ce champ est requis.'
        if ('land_grid' in fieldValues) temp.land_grid = fieldValues.land_grid ? '' : 'Ce champ est requis.'
        if ('mangrove_class' in fieldValues)
            temp.mangrove_class = fieldValues.mangrove_class ? '' : 'Ce champ est requis.'
        if ('surface' in fieldValues)
            temp.surface = fieldValues.surface
                ? /^-?\d*[.,]?\d*$/.test(fieldValues.surface)
                    ? ''
                    : 'Entrer un nombre'
                : 'Ce champ est requis.'
        // if ('substrat_color' in fieldValues)
        //     temp.substrat_color = fieldValues.substrat_color ? '' : 'Ce champ est requis.'
        // if ('depth' in fieldValues) temp.depth = fieldValues.depth ? '' : 'Ce champ est requis.'
        // if ('soil_texture' in fieldValues) temp.soil_texture = fieldValues.soil_texture ? '' : 'Ce champ est requis.'
        // if ('salinity' in fieldValues) temp.salinity = fieldValues.salinity ? '' : 'Ce champ est requis.'
        // if ('specie_fauna' in fieldValues) temp.specie_fauna = fieldValues.specie_fauna ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        // return false if values.site, values.mangrove_class, values.surface is null
        if (!values.site || !values.mangrove_class || !values.surface) {
            return false
        }
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
        //return false if one of above conditions is true
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    useEffect(() => {
        if (action === 'add') {
            setDisabledField(false)
            const getLandDataAdd = async () => {
                setLoading(true)
                const landData = await LandService.load().getDataAdd()
                if (landData) {
                    console.log(landData.data)
                    setDataAdd(landData.data)
                }
                setLoading(false)
            }
            if (action) {
                getLandDataAdd()
            }
        }
    }, [action, setNeedLoad])

    useEffect(() => {
        if (addedTech) {
            console.log('addedTech', addedTech)
            const tech = dataAdd.technicianDataAdd.find((row) => row.id === addedTech)
            tech.assigned_by = user.id
            setDataTechAdd([...dataTechAdd, tech])
            setValues({ ...values, list_tech: [...dataTechAdd, tech] })
            setAddedTech(null)
        }
    }, [addedTech, dataTechAdd, dataAdd, user, setValues, values])

    useEffect(() => {
        if (photos) {
            setValues({ ...values, photos: photos })
        }
    }, [photos, values, setValues])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(values)
        if (validate()) {
            values.surface = convertHaToM2(values.surface)
            console.log(values)
            const response = await addOrEdit(
                { ...values, user: [user.firstname, user.lastname].join(' ') },
                resetForm,
                LandService,
                setNeedLoad,
                setUserHaveToPost
            )
            if (response) {
                setAddedTech(null)
                navigate('/app/mangrove/land')
            }
        } else {
            setErrors({
                ...errors,
                site: !values.site ? 'Ce champ est requis.' : '',
                mangrove_class: !values.mangrove_class ? 'Ce champ est requis.' : '',
                surface: !values.surface ? 'Ce champ est requis.' : ''
            })
            console.log('error', errors)
            notification('Veuiller remplir les champs obligatoires !', 0)
        }
    }

    const openAddTech = () => {
        setOpenPopup(true)
    }

    const handleAddPhoto = (value) => {
        setLandImages([...landImages, value])
        setValues({ ...values, photos: [...landImages, value] })
    }

    useEffect(() => {
        if (dataAdd) {
            console.log(dataAdd)
            setRegionsList(dataAdd.regions)
        }
    }, [dataAdd])

    const handleSelectedRegion = (event) => {
        console.log('target : ', event.target)
        setDistrictList(dataAdd.regions.find((row) => row.id === event.target.value).District)
        setSelectedRegion(event.target.value)
    }

    const handleSelectedDistrict = (event) => {
        setCommuneList(districtList.find((row) => row.id === event.target.value).Commune)
        setSelectedDistrict(event.target.value)
    }

    const handleSelectedCommune = (event) => {
        setFktList(communeList.find((row) => row.id === event.target.value).Fokontany)
        setSelectedCommune(event.target.value)
    }

    const handleSelectedFkt = (event) => {
        setVillageList(fktList.find((row) => row.id === event.target.value).Village)
        setSelectedFkt(event.target.value)
    }

    return (
        <>
            <Loader isLoading={loading} />

            {dataAdd && (
                <AddTech
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    techData={dataAdd.technicianDataAdd}
                    setAddedTech={setAddedTech}
                />
            )}
            <Form onSubmit={handleSubmit}>
                <Container
                    sx={{
                        paddingTop: '1rem',
                        paddingBottom: '1rem'
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <p style={{ color: '#677788', paddingBottom: '0.5rem', fontSize: '0.7rem' }}>Technicien</p>
                            {dataAdd && (
                                <>
                                    <Paper sx={{ padding: '0.4rem', borderRadius: '0.5rem', display: 'inline-flex' }}>
                                        {dataTechAdd &&
                                            dataTechAdd.map((tech, index) => (
                                                <Chip
                                                    key={index}
                                                    style={{
                                                        color: '#677788',
                                                        fontSize: '0.7rem',
                                                        margin: '0.2rem',
                                                        height: '1.3rem'
                                                    }}
                                                    label={tech?.name}
                                                />
                                            ))}
                                    </Paper>
                                    <Container
                                        sx={{
                                            paddingTop: '0',
                                            marginTop: '0 10px 0 10px',
                                            display: 'inline-flex',
                                            width: 'inherit'
                                        }}
                                    >
                                        <ChipButton onClick={openAddTech} title="Ajouter" />
                                    </Container>
                                </>
                            )}
                            <Paper sx={styles.paper}>
                                <VillageAutoCompleteSection
                                    values={values}
                                    setValues={setValues}
                                    errors={errors}
                                    onChange={handleInputChange}
                                    isRequired={true}
                                />
                                <Paper>
                                    {action === 'add' && (
                                        <AddLandInformations
                                            mangroClassDataAdd={dataAdd?.mangroClassDataAdd}
                                            styles={styles}
                                            disabledField={disabledField}
                                            values={values}
                                            errors={errors}
                                            handleInputChange={handleInputChange}
                                            setValues={setValues}
                                        />
                                    )}
                                </Paper>
                                <Paper>
                                    <AddSavedParameters
                                        styles={styles}
                                        values={values}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        setValues={setValues}
                                        dataAdd={dataAdd}
                                    />
                                </Paper>
                                <Paper sx={{ paddingBottom: '1rem' }}>
                                    <AddTopography
                                        styles={styles}
                                        disabledField={disabledField}
                                        handleInputChange={handleInputChange}
                                        setValues={setValues}
                                        values={values}
                                    />
                                </Paper>
                                {
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Paper sx={styles.paperInput}>
                                                <Controls.AutoCompleteSelect
                                                    label="Cause de dégradation"
                                                    name="degradation_cause"
                                                    options={dataAdd?.degradationCauseDataAdd || []}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    onChange={handleInputChange}
                                                    value={values.degradation_cause}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Paper sx={{ ...styles.paperInput }}>
                                                <Controls.DynamicTextInput
                                                    placeholder={'Texte explicatif'}
                                                    customStyle={{
                                                        height: '70px',
                                                        marginBottom: '10px'
                                                    }}
                                                    name="degradation_explanation"
                                                    value={values.degradation_explanation}
                                                    onChange={handleInputChange}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper sx={{ ...styles.paperInput }}>
                                                <Controls.DatePicker
                                                    label="Date de dégradation"
                                                    name="degradation_date"
                                                    value={values.degradation_date}
                                                    onChange={handleInputChange}
                                                    error={errors.degradation_date}
                                                    getYearOnly={true}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper sx={{ ...styles.paperInput, paddingBottom: '1rem' }}>
                                                <Controls.RadioGroup
                                                    label="Présence des maladies"
                                                    name="have_desease"
                                                    items={deseaseOption}
                                                    value={values.have_desease}
                                                    onChange={handleInputChange}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper sx={{ ...styles.paperInput }}>
                                                <Controls.RadioGroup
                                                    label="Changement d'utilisation de terre"
                                                    name="use_land_changement"
                                                    items={landChangementOption}
                                                    value={values.use_land_changement}
                                                    onChange={handleInputChange}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Paper sx={{ ...styles.paperInput }}>
                                                <Controls.DynamicTextInput
                                                    placeholder={'Texte explicatif'}
                                                    customStyle={{
                                                        height: '70px',
                                                        marginBottom: '10px'
                                                    }}
                                                    name="use_change_explanations"
                                                    value={values.use_change_explanations}
                                                    onChange={handleInputChange}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                <Paper sx={{ ...styles.paperInput, paddingBottom: '1rem' }}>
                                                    <Typography sx={{ ...styles.form_title, color: '#687889' }}>
                                                        Photo
                                                    </Typography>

                                                    <Paper
                                                        //display photos on line and add margin left and right 5px for each photo
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'left',
                                                            margin: '-5px'
                                                        }}
                                                    >
                                                        {landImages.map((image) => (
                                                            <Controls.AvatarContainer
                                                                place="left"
                                                                size={80}
                                                                src={image}
                                                                customStyle={{ marginRight: '10px' }}
                                                            />
                                                        ))}
                                                    </Paper>
                                                    <Controls.MultipleImageInput
                                                        label="Photos"
                                                        onChange={handleInputChange}
                                                        name="photos"
                                                        value={values?.photos}
                                                        setPhoto={setPhotos}
                                                    />
                                                    {/* <Controls.ImageInput
                                                        error={errors.photos}
                                                        value={values.photos}
                                                        name="photos"
                                                        onChange={(e) => {
                                                            handleAddPhoto(e.target.content)
                                                        }}
                                                        customizedButton={true}
                                                        setValueImage={setValueImage}
                                                    >
                                                        <Avatar
                                                            variant="rounded"
                                                            sx={{
                                                                //add backgroud color
                                                                backgroundColor: '#f1f1f1',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: '#f1f1f1'
                                                                }
                                                            }}
                                                            className={styles.addPhoto}
                                                            //get image src from assets
                                                            src={addImage}
                                                            //ajouter une alt quand le bouton est survolé par le pointer de la souris
                                                            alt="Ajouter une photo"
                                                        />
                                                    </Controls.ImageInput> */}
                                                </Paper>
                                            }
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Paper sx={{ ...styles.paperInput }}>
                                                <Controls.Input
                                                    label="Note"
                                                    name="notes"
                                                    value={values.notes}
                                                    onChange={handleInputChange}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                }
                            </Paper>
                        </Grid>

                        {action === 'add' && (
                            <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Controls.Button
                                    type="submit"
                                    text="Ajouter"
                                    size={'large'}
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                />
                            </Container>
                        )}
                    </Grid>
                </Container>
            </Form>
        </>
    )
}

export default AddLandDetailsContent
