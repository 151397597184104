import { Card, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DateNavigate from '../navigate/DateNavigate'
import Subtitle from '../../../../../../../../components/Typography/Subtitle'
import Controls from '../../../../../../../../components/Entry/Controls'
import ToolbarButton from '../../../../../../../../components/DataTable/ToolbarButton'
import TrackingSemisService from '../../../../../../services/semis/TrackingSemisService'
import { useAuth } from '../../../../../../../../applications/hooks/UseAuth'
import AddTrackingSemis from './AddTrackingSemis'
import Popup from '../../../../../../../../layouts/Popup'

const specificSemisInitialValues = {
    tracking_date: '',
    plant_dead: 0,
    plant_lived: 0,
    plant_germed: 0,
    plant_ready: 0,
    problems: '',
    soluces: '',
    tracking_photos: null
}
function YoungPlantDevelopment(props) {
    const { styles, setNeedLoad, needLoad, semisSelected, semisTrackingData, selectedSpecie } = props
    const [semisTrackingSelectedDate, setSemisTrackingSelectedDate] = useState(null)
    const [specificSemisTracking, setSpecificSemisTracking] = useState(null)
    const [ableToAddTracking, setAbleToAddTracking] = useState(false)
    const [dateSelected, setDateSelected] = useState('')
    const [openPopup, setOpenPopup] = useState(false)
    const useDrawer = false
    const service = TrackingSemisService.load()
    const { haveAccessTo } = useAuth()

    useEffect(() => {
        if (selectedSpecie) {
            setDateSelected('')
            setSpecificSemisTracking(specificSemisInitialValues)
        }
    }, [selectedSpecie])

    useEffect(() => {
        if (dateSelected) {
            console.log(dateSelected)
            setSpecificSemisTracking(dateSelected)
        }
    }, [dateSelected])

    return (
        <>
            <Paper sx={styles.paper}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        padding: '10px 10px 20px 10px'
                    }}
                >
                    <Typography sx={{ ...styles.form_title, padding: '5px' }}>Développement</Typography>
                    {ableToAddTracking && (
                        <ToolbarButton
                            moduleTitle="d'un suivi des jeunes plants"
                            service={service}
                            drawer={useDrawer}
                            haveAccessTo={haveAccessTo}
                            setNeedLoad={setNeedLoad}
                            needLoad={needLoad}
                            Form={AddTrackingSemis}
                            size="small"
                            objectRelated={semisSelected}
                        />
                    )}
                </Paper>
                <Container>
                    <DateNavigate
                        // semisTrackingSelected={semisTrackingSelected}
                        selectedSpecie={selectedSpecie}
                        semisTrackingData={semisTrackingData}
                        setSemisTrackingSelectedDate={setSemisTrackingSelectedDate}
                        dateSelected={dateSelected}
                        setDateSelected={setDateSelected}
                        setSpecificSemisTracking={setSpecificSemisTracking}
                        specificSemisInitialValues={specificSemisInitialValues}
                    />
                    {/* youg plants informations */}
                    <Paper
                        sx={{
                            '& .MuiContainer-root': {
                                padding: '0'
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item xs={6}>
                                <InfoPlantCart
                                    title="Nombre des plants ayant germé"
                                    number={specificSemisTracking?.plant_germed || '0'}
                                    color="#a5d1eb"
                                    selectorColor="#a5d1eb"
                                    numberColor=" #a5d1eb"
                                />
                                <InfoPlantCart
                                    title="Nombre de jeunes plants mort"
                                    number={specificSemisTracking?.plant_dead || '0'}
                                    color="#cc7722"
                                    selectorColor="#cc7722"
                                    numberColor=" #cc7722"
                                />
                                <Paper sx={{ margin: '10px 10px 10px 10px' }}>
                                    <Controls.TextInput
                                        label="Problèmes rencontrés"
                                        value={specificSemisTracking?.problems || ''}
                                        isDisabled={true}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <InfoPlantCart
                                    title="Nombre de vivants"
                                    number={specificSemisTracking?.plant_lived || '0'}
                                    color="#00ff00"
                                    selectorColor="#00ff00"
                                    numberColor=" #00ff00"
                                />

                                <InfoPlantCart
                                    title="Nombre des plants prêts à être reboisé"
                                    number={specificSemisTracking?.plant_ready || '0'}
                                    color="#91fee4"
                                    selectorColor="#91fee4"
                                    numberColor=" #91fee4"
                                />
                                <Paper sx={{ margin: '10px 10px 10px 10px' }}>
                                    <Controls.TextInput
                                        label="Solutions proposés"
                                        value={specificSemisTracking?.soluces || ''}
                                        isDisabled={true}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <div>
                            <Controls.Button
                                text="Photos de suivi"
                                onClick={setOpenPopup}
                                disabled={specificSemisTracking?.tracking_photos ? false : true}
                            />
                        </div>
                    </Paper>
                </Container>
            </Paper>
            <Popup title={'Photos de suivi'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <div style={{ width: '700px', height: '500px' }}>
                    {specificSemisTracking?.tracking_photos && (
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                            {specificSemisTracking?.tracking_photos.map((photo, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Controls.CustomImageAvatar key={index} src={photo} size={100} />
                                    <p>photo {index + 1}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Popup>
        </>
    )
}

function InfoPlantCart(props) {
    const { title, number, color, selectorColor, numberColor } = props
    return (
        <>
            <Grid
                item
                sx={{
                    padding: '0px 10px 5px 10px'
                }}
            >
                <Card
                    style={{
                        width: '100%',
                        padding: '20px 0px 20px 0px',
                        margin: '10px',
                        borderRadius: '10px',
                        height: '70px',
                        boxShadow: 'rgba(0, 0, 0, 0.07) 21px 5px 27px',
                        background: `linear-gradient(to left, ${color} 0%, white 43%)`
                    }}
                >
                    <Container>
                        <div>
                            <Grid container>
                                <Grid item xs={4} sx={{}}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={0.5}
                                            sx={{
                                                backgroundColor: `${selectorColor}`,
                                                borderRadius: '3px',
                                                marginRight: '8px'
                                            }}
                                        ></Grid>
                                        <Grid item sx={1}>
                                            <Subtitle
                                                style={{
                                                    fontSize: '0.9rem',
                                                    color: `${numberColor}`
                                                }}
                                            >
                                                {number}
                                            </Subtitle>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7.5}>
                                    <p
                                        style={{
                                            fontSize: '0.7rem',
                                            color: '#677788'
                                        }}
                                    >
                                        {title}
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Card>
            </Grid>
        </>
    )
}

export default YoungPlantDevelopment
