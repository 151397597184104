import React, { useEffect, useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import { format } from 'date-fns'

const { DatePicker } = Controls
function DateSetInput(props) {
    const { data, onChange, value } = props
    const [onCharge, setOnCharge] = useState(true)

    let handleDateChange = (date) => {
        const formattedDate = format(date.target.value.$d, 'yyyy-MM-dd')
        onChange(formattedDate.toString())
    }

    let dateNow = new Date().toISOString().split('T')[0]

    useEffect(() => {
        if (onCharge) {
            onChange(dateNow.toString())
            setOnCharge(false)
        }
    }, [onCharge, dateNow, onChange])
    return (
        <>
            <DatePicker label={data.question} onChange={handleDateChange} value={value || dateNow.toString()} />
        </>
    )
}

export default DateSetInput
