import React from 'react'
import { NurseryDashboard } from './components/NurseryDashboard'
import { PLantingDashBord } from './components/PlantingDashboard'
import PlotDashboard from './components/PLotDashboard'
import { PeasantDashBord } from './components/PeasantDashBoard'

export default function Dashboard() {
    return (
        <div className="flex flex-col gap-4">
            <NurseryDashboard />
            <div className="flex gap-4">
                <div className="w-[70%]">
                    <PLantingDashBord />
                </div>
                <div className="w-[30%] mr-[10px]">
                    <PlotDashboard />
                </div>
            </div>
            <div className="w-full">
                <PeasantDashBord />
            </div>
        </div>
    )
}
