import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import OutingService from './services/outing'
import OutingTable from './components/OutingTable'
import { useAuth } from '../../../applications/hooks/UseAuth'
import PageHeader from '../../../components/Typography/PageHeader'
import Controls from '../../../components/Entry/Controls'
import OutingView from './components/OutingContent'

const CURRENT_PAGE = 'agroforestry_plantation'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

function OutingMangrove() {
    const classes = useStyles()

    const service = OutingService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true

    const [value, setValue] = useState('outing')

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue)
        navigate('/app/mangrove/nursery/' + newValue)
    }

    const addAction = () => {
        navigate('/app/mangrove/nursery/outing/new', {
            state: {
                userHaveToAdd: true
            }
        })
    }

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader title={'Sortie en pépinière'} subTitle={'Sortie en pépinière'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <Controls.Button text="Nouveau" variant="contained" size="large" onClick={addAction} />
                </div>
            </PageHeader>
            <MuiToolbar style={{ padding: 0, margin: '20px 0 0 0' }} className={classes.siteChange}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    <BottomNavigationAction key="1" label="Plate-bande" value="plat" />
                    <BottomNavigationAction key="2" label="Suivi des jeunes plants" value="tracking" />
                    <BottomNavigationAction key="3" label="Sortie en pépinière" value="outing" />
                </BottomNavigation>
            </MuiToolbar>
            <OutingTable
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={OutingView}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </div>
    )
}

export default OutingMangrove
