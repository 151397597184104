import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'

import Controls from '../../components/Entry/Controls'
import PageHeader from '../../components/Typography/PageHeader'
import { useAuth } from '../../applications/hooks/UseAuth'
import OrderTrackingList from './components/OrderTrackingList'
import OrderTrackingService from './services/OrderTracking'
import OrderTrackingView from './components/OrderTrackingView'

const CURRENT_PAGE = 'agroforestry_plantation'

function OrderTracking() {
    const service = OrderTrackingService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)

    const navigate = useNavigate()

    const addAction = () => {
        navigate('/app/commercial/order/new')
    }

    return (
        <>
            <PageHeader title={'Gestion Commandes'} subTitle={'Gestion Commandes'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <Controls.Button text="Nouveau" variant="contained" size="large" onClick={addAction} />
                </div>
            </PageHeader>

            <OrderTrackingList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={OrderTrackingView}
                drawer={true}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default OrderTracking
