import React, { useEffect, useState } from 'react'
import Loader from '../../../../../components/Entry/Loading'
import { getCodeCollection } from '../../../utils/function/DataSet'
import FormService from '../../../services/Form'
import CustomListSpecies from './CollectionSpecies'
import CollectionPeasant from './CollectionPeasant'
import Collection from './Collection'

function CollectionInput(props) {
    const { data, onChange, label } = props
    const [loading, setLoading] = useState(true)
    const [dataCollection, setDataCollection] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [dataCode, setDataCode] = useState('')
    const formService = FormService.load()

    useEffect(() => {
        setLoading(true)
        if (data) {
            let code = getCodeCollection(data)
            setDataCode(code)
            formService
                .getCollection(code)
                .then((response) => {
                    setDataCollection(response.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [data, formService])

    const handleInputChange = (e) => {
        const value = e.target.value
        setSearchTerm(value)
        onChange(value)
    }

    const onItemClick = (id, name) => {
        onChange(id)
        setSearchTerm(name)
    }

    const handleValuesChange = (values) => {
        onChange(values)
    }

    return (
        <>
            <Loader loading={loading} />

            {dataCode === 'species' && dataCollection.length > 0 ? (
                <CustomListSpecies
                    label={label}
                    filteredData={dataCollection}
                    onItemClick={onItemClick}
                    onValuesChange={handleValuesChange}
                />
            ) : dataCode === 'peasants' && dataCollection.length > 0 ? (
                <CollectionPeasant label={label} data={dataCollection} onChange={onChange} />
            ) : (
                dataCollection.length > 0 && <Collection label={label} data={dataCollection} onChange={onChange} />
            )}
        </>
    )
}

export default CollectionInput
