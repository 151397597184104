import React, { useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import FormContent from '../../../../components/PageView/Form/FormContent'
import FirstFormView from './form/FirstFormView'
import Loader from '../../../../components/Entry/Loading'
import { Box, Container, Paper, Typography } from '@mui/material'
import { UseStyles } from '../../utils/Styles'
import Controls from '../../../../components/Entry/Controls'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../components/Entry/Map'
import QrViewer from '../../../../components/Entry/QrViewer'

function PlotViewForm(props) {
    const { records, setNeedLoad } = props
    const classes = UseStyles()
    const [loading, setLoading] = useState(false)
    const [plotPhotos, setPlotPhotos] = useState(null)
    const [plotGrid, setPlotGrid] = useState(null)

    useEffect(() => {
        console.log('records :>>', records)

        if (records?.photos) {
            setPlotPhotos(JSON.parse(records?.photos))
        }
        if (records && records?.quadrillage) {
            setPlotGrid([
                {
                    id: 1,
                    coordinates: records?.quadrillage?.coordinates
                }
            ])
        }
    }, [records])

    return (
        <>
            <Loader isLoading={loading} />
            <CssBaseline />
            <Grid container className={classes.root}>
                {/* Première colonne (2/3 de la page) */}
                <Grid item xs={8} sx={{ paddingTop: '20px' }}>
                    {/* <div className={classes.paper}>
                        <FormContent title={`Information Terrains`} Form={FirstFormView} data={records} setNeedLoad={setNeedLoad} />
                    </div> */}
                    <Container>
                        <FirstFormView records={records} setNeedLoad={setNeedLoad} />
                    </Container>
                </Grid>
                {/* Deuxième colonne (1/3 de la page) */}
                <Grid item xs={4}>
                    <Container className={classes.coordinateContainer}>
                        <LoadScript>{plotGrid && <Map data={plotGrid} zoom={10} />}</LoadScript>
                        {!plotGrid && <Typography style={{ textAlign: 'center' }}>(Aucun quadrillage.)</Typography>}
                    </Container>
                    <Box sx={{ paddingTop: '40px' }}>
                        <Container>
                            <QrViewer value={records?.code_qr} size={'60%'} />
                        </Container>
                    </Box>
                </Grid>
            </Grid>
            {plotPhotos && (
                <Grid className={classes.photoContainer}>
                    <Typography
                        style={{
                            fontSize: '0.9rem',
                            color: '#677788'
                        }}
                    >
                        Photo
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {plotPhotos.map((photo, index) => (
                            <Controls.CustomImageAvatar
                                key={index}
                                title="Photo de la parcelle"
                                place="left"
                                size={80}
                                src={`${photo}`}
                            />
                        ))}
                    </div>
                </Grid>
            )}
        </>
    )
}

export default PlotViewForm
