import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class LandService extends Service {
    static messages = {
        update_success: 'Modification parcelle avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création parcelle avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/land')
        return this
    }

    static async getByCode(code) {
        return await Http.get(`${this.url}/${code}`, {})
    }

    static async getDataAdd() {
        return await Http.get(`${this.url}/add/data`, {})
    }
    static async getAllLand(site = null) {
        const params = site ? {site: site} : {}
        return await Http.get(`/api/land`, params)
    }

    static async create(data) {
        return await Http.post(`${this.url}`, data)
    }
}

export default LandService
