import React, { createContext, useEffect, useState } from 'react'
import FormService from '../services/Form'
import Loader from '../../../components/Entry/Loading'

export const ContextForm = createContext()

function FormContext({ children, code, needLoad }) {
    const formService = FormService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
        if (code) {
            const getFormData = async () => {
                setLoading(true)
                const formData = await formService.getFormByCode(code)
                if (formData) {
                    setValue(formData?.data)
                }
                setLoading(false)
            }
            if (needLoad) {
                getFormData()
            }
        }
    }, [code, formService, needLoad])

    return (
        <>
            <Loader isLoading={loading} />
            <ContextForm.Provider value={value}>
                {children}
            </ContextForm.Provider>
        </>
    )
}

export default FormContext
