import React, { useEffect, useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import Loader from '../../../../../components/Entry/Loading'
import FormService from '../../../services/Form'

const { Input, Button, DatePicker, Select, CustomSelect } = Controls

function LocationAgroLocation(props) {
    const { data, onChange } = props
    const defaultData = {
        id: 0,
        name: '',
        title: ''
    }
    const [loading, setLoading] = useState(false)
    const [siteData, setSiteData] = useState([defaultData])
    const [dataFkt, setDataFkt] = useState([defaultData])
    const [dataVillage, setDataVillage] = useState([defaultData])

    const [villageData, setVillageData] = useState('')

    const [selectedSite, setSelectedSite] = useState('')
    const [selectedFkt, setSelectedFkt] = useState('')
    const [selectedVillage, setSelectedVillage] = useState('')

    useEffect(() => {
        setLoading(true)
        FormService.getAgroLocation()
            .then((response) => {
                if (response?.data) {
                    setSiteData(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
        setLoading(false)
    }, [])

    useEffect(() => {
    }, [siteData])

    const handleSelectedSite = (e) => {
        const selectedSiteData = siteData.find((item) => item.id === e.target.value)
        setDataFkt(selectedSiteData.fkt)
        setSelectedSite(e.target.value)
    }

    const handleSelectedFkt = (e) => {
        const selectedSiteFkt = dataFkt.find((item) => item.id === e.target.value)
        setDataVillage(selectedSiteFkt.villages)
        setSelectedFkt(e.target.value)
    }

    const handleSelectedVillage = (e) => {
        const selectedSiteVillage = dataVillage.find((item) => item.id === e.target.value)
        setSelectedVillage(e.target.value)
        setVillageData(selectedSiteVillage)
        onChange(selectedSiteVillage.id)
    }


    return (
        <>
            <Loader isLoading={loading} />
            {siteData && (
                <>
                    <CustomSelect
                        label="Site"
                        options={siteData|| [defaultData]}
                        value={selectedSite || ''}
                        onChange={handleSelectedSite}
                        labelKey="name"
                        valueKey="id"
                    ></CustomSelect>
                    <CustomSelect
                        label="Fokontany"
                        options={dataFkt || [defaultData]}
                        value={selectedFkt || ''}
                        onChange={handleSelectedFkt}
                        labelKey="name"
                        valueKey="id"
                    ></CustomSelect>
                    <CustomSelect
                        label="Village"
                        options={dataVillage|| [defaultData]}
                        value={selectedVillage || ''}
                        onChange={handleSelectedVillage}
                        labelKey="name"
                        valueKey="id"
                    ></CustomSelect>
                    
                    <Input label={data.options[0].label} value={villageData.name} />
                </>
            )}
        </>
    )
}

export default LocationAgroLocation
