import React, { useEffect } from 'react'
import { Button as MuiButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0.5
    },
    label: {
        textTransform: 'none'
    },
    background: {
        backgroundColor: '#0FCB8F'
    }
}))

export default function Button(props) {
    const { text, size, color = 'primary', variant, onClick, disabled = false, haveSpin, ...other } = props
    const classes = useStyles()

    return (
        <MuiButton
            style={{
                backgroundColor: disabled === false ? color === 'error' ? '#f44336' : '#0FCB8F' : '#ccc',
                borderRadius: 10,
                color: '#fff',
                textTransform: 'unset',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
            }}
            variant={variant || 'contained'}
            size={size || 'small'}
            color={color}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}
            //set button disabled
            disabled={disabled}
        >
            {text}
            {haveSpin && disabled && <CircularProgress size="15px" color="inherit" />}
        </MuiButton>
    )
}
